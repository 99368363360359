import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-add-faq',
  templateUrl: './add-faq.component.html',
  styleUrls: ['./add-faq.component.css']
})
export class AddFaqComponent implements OnInit {
  addFaqForm:FormGroup;
  pageNumber:number=1
  currTab: any;
  constructor(public route:Router,public service:ServiceService, public active:ActivatedRoute) 
  {
    this.active.queryParams.subscribe((params)=>{
      this.currTab=params.tab
      
    })
   }

  ngOnInit(): void {
    this.formValidation();
    this.faqCategoryList()
  }

  formValidation(){
    this.addFaqForm= new FormGroup({
      'title':new FormControl('', [Validators.required]),
      'description': new FormControl('', [Validators.required]),
      'faqCategory' :new FormControl('', [Validators.required])
    })
  }

faqDataCatgory:any = []
totalRecords:any
itemsPerPage:any = 100
  faqCategoryList(){
    let url =`static/admin/static-content/get-FAQ-category-list`
    this.service.showSpinner();
    this.service.get(url).subscribe((res:any)=>{
      if(res.status==200){
        this.service.hideSpinner()
        this.faqDataCatgory=res.data;
        this.totalRecords = res.data.Count ? res.data.List.totalElements : '';
         
        
      }

      this.service.hideSpinner()
      
    },(err)=>{
      if(err['status']==401){
        this.service.hideSpinner()
        this.service.toasterErr('Unauthorized Access')
      }
      else{
        this.service.hideSpinner()
        this.service.toasterErr('Please wait for few minutes & reload the site.');
     }
    })
  }
  addFaqLanguage(){
    if(this.currTab=='English'){
      this.addFaq();
    }
  }

  
  addFaq(){
    let request = {
      'answer':this.addFaqForm.value.description,
      'question':this.addFaqForm.value.title,
      'faqId' : this.addFaqForm.value.faqCategory 
    }
   this.service.post(`static/admin/static-content/add-faq-question-answer`,request).subscribe((res:any)=>{
     if (res.status=200) {
       this.service.toasterSucc(res.message)
       this.route.navigate(['/faq-management'])
     }
    },err=>{
   
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr('Please wait for few minutes & reload the site.');
   }
    })

  }

}
