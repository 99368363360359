import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { PageEvent } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


declare var $: any
@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  totalRecord: any;
  referalDataLevel:any = []
  pageNumber: number = 1;
  itemPerPage: number = 100
  pageSize = 25;
  pageSizeOptions: number[] = [5, 10, 25,50,75,100];
  pageEvent: PageEvent;
  totalItems: any;
  fromDate: any = ''
  beDisable: boolean = true;
  userForm: FormGroup;
  userID: any;
  IsToDate: boolean = true;
  calender: any = { todate: '', formdate: '' }
  action: any;
  userList: any = [];
  searchText: string = ''
  userDetail: any = {};
  space: string = ' ';
  convertFormat: any;
  newArray: any = [];
  newFromDate: number;
  userTotalCount:any=[]
  newToDate: any;
  countryList: any = []
  stateListArr:any = []
  stateList: any = [];
  countryCode: any;
  stateSelect: FormControl = new FormControl();
  cities: any = [];
        ShowFilter = false;
        limitSelection = false;
        selectedItems: any = [];

  closeDropdownSelection=false;
  disabled=false;
  dropdownSettings: IDropdownSettings = {
    singleSelection: true, // Set to 'true' for single selection
    idField: 'name',
    textField: 'name',
    allowSearchFilter: true,
    enableCheckAll: false,
    limitSelection: 1,
    itemsShowLimit: 1,
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
  };
  constructor(
    private router: Router, public service: ServiceService,private datePipe:DatePipe,  private http:HttpClient
  ) {

  }

  ngOnInit() {
    this.userForm = new FormGroup({
      'state': new FormControl(''),
      'startdate': new FormControl(''),
      'enddate': new FormControl(''),
      'searchText': new FormControl(''),
      'sedan': new FormControl(''),
      'hatch': new FormControl(''),
      'suv': new FormControl(''),
      'country': new FormControl(''),
      'status': new FormControl('')
    })
    this.onDateChanged();
    this.getUserList();
    this.countryList = this.service.countryListJson;
    this.getStaticList()
  }
 
  valueState:any
  onItemSelect(item: any) {
    this.valueState = item.name
    console.log(this.stateSelect);
    
}
 // Get StateList Functionality
 getStateList(countryCode) {
  // var stateList = this.countryList.filter(x => (x.country == event.target.value));
  var stateList = this.countryList.filter((x) => x.country == countryCode);
  this.stateList = stateList[0].states;
  this.countryCode = stateList[0].code;
}
toggleCloseDropdownSelection() {
    this.closeDropdownSelection = !this.closeDropdownSelection;
    this.dropdownSettings = Object.assign({}, this.dropdownSettings,{closeDropDownOnSelection: this.closeDropdownSelection});
}
  reset() {
    if (
      this.userForm.value.searchText ||
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.sedan ||
      this.userForm.value.hatch ||
      this.userForm.value.suv ||
      this.userForm.value.country ||
      this.userForm.value.status || this.userForm.value.state


    ) {
      this.userForm.reset({
        startdate: "",
        enddate: "",
        searchText: "",
        sedan: '',
        hatch: '',
        suv: '',
        country: '',
        status: '',
        state: '',
      });
      this.getUserList();
    }
  }

  // getCountryList() {
  //   this.service.get('account/get-country-list').subscribe((res) => {
  //     if (res['status'] == 200) {
  //       this.countryList = res['data']
  //     }
  //   })
  // }

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  public toDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };
  unverifiedMessage(){
    this.service.toasterErr('This user is unverified')
  }
  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }
 
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }


  public onChange(event: IMyDateModel) {
    if (event.formatted) {
      this.beDisable = false
      let d: Date = new Date(event.jsdate.getTime());
      d.setDate(d.getDate() - 1);
      let copy: IMyDpOptions = this.getCopyOfToDateOpt();
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.toDate = copy;
    }

  }
  getCopyOfToDateOpt(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toDate));
  }

  getUserList() {
    var url = `account/admin/user-management/filter-user-details?page=${this.pageNumber -1}&pageSize=${this.itemPerPage}`;
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userList = res['data']['list'];
        this.totalItems = res.data.totalCount;
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.hideSpinner()
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }


  pagination(page) {

    this.pageNumber = page
    this.getUserList()

  }

  applyFilter(val) {
    if (val == 'date') {
      var startdate = this.userForm.value.startdate.epoc ? (Number(this.userForm.value.startdate.epoc) * 1000) : ''
      var enddate = this.userForm.value.enddate.epoc ? (Number(this.userForm.value.enddate.epoc) * 1000) : ''
      var url = "account/admin/user-management/filter-user-details?fromDate=" + startdate + "&page=" + (this.pageNumber -1) + "&toDate=" + enddate + "&pageSize=" + this.itemPerPage;
    } else {
      var url = "account/admin/user-management/filter-user-details?page=" + (this.pageNumber -1 ) + "&search=" + this.userForm.value.searchText + "&pageSize=" + this.itemPerPage;
    }
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userList = res['data']['list'];
        this.totalItems = res['data']['totalCount'];

      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }


  openModal(action, userId) {
    this.userID = userId;
    this.action = action;
    if (action == 'DELETE') {
      $('#deleteModal').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }

  userdetails(userId, email) {
    this.router.navigate(['/user-details/'], { queryParams: { userId, email } })
  }
  walletdetail(userId) {
    this.router.navigate(['walletdetails/' + userId])
  }
  getExcelExport() {
    var url = `account/admin/user-management/filter-user-details?page=${this.pageNumber -1}&pageSize=${this.totalItems}`;
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.exportAsXLSX(res['data']['list'])
      }
    }, err => {
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  exportAsXLSX(userList) {
    let dataArr = [];
    userList.forEach((element, ind) => {
      dataArr.push({
        "S.No.": ind + 1,
        "Unique ID": element.randomId ? element.randomId : '--',
        "Referral ID": element.myReferralCode  ? element.myReferralCode : '--',
        "User Name": element.firstName + '' + element.lastName ? element.firstName : '',
        "Referral User Name": element.referredUniqueName  ? element.referredUniqueName : '--',
        "Name":  element.firstName ? element.firstName : '' + ' ' +element.middleName ? element.middleName :'' + ' ' +element.lastName  ? element.lastName  :'' ,
        "Email": element.email ? element.email : '--',
        "Mobile Number":   element.phoneNo ? element.phoneNo : '--',
        "Country" :element.country ? element.country : '--',
        "Kyc Status":element.kycStatus ? element.kycStatus  :'--',
        "User Status": element.userStatus ? element.userStatus : '--',
        "Registration Date & Time ": element.createTime ? this.datePipe.transform(element.createTime,'medium') : '--',
        "Two-FA-Type" : element.twoFaType ?  element.twoFaType  : '--',
        // "Address": element.address ? element.address : '--',
        // "City" : element.city ? element.city : '--',
        // "State": element.state ? element.state : '--',
        // "ZipCode":element.zipCode ? element.zipCode : '--',
        // "Date of Birth" : element.dob ? element.dob : '--',
        // "Gender" : element.gender ?  element.gender  : '--',
      })
    })

    this.service.exportAsExcelFile(dataArr, 'User Management');
  }


  deleteFunction() {
    var url = 'account/admin/user-management/delete-user-detail?userIdToDelete=' + (this.userID) + '&ipAddress=' + (6754675467) + '&location=' + ('unknown');
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        $('#deleteModal').modal('hide')
        this.service.toasterSucc('User deleted successfully');
        this.getUserList();
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  performAction() {
    var url = 'account/admin/user-management/user-status?ipAddress=' + (11) + '&location=' + ('unknown') + '&userIdForStatusUpdate=' + (this.userID) + '&userStatus=' + (this.action);
    this.service.showSpinner();
    this.service.post(url, '').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == 'BLOCK') {
          $('#block').modal('hide');
          this.service.toasterSucc('User blocked successfully');
        }
        else {
          $('#active').modal('hide');
          this.service.toasterSucc('User activated successfully');
        }
        this.getUserList();
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  searchByDate() {
    // if (this.userForm.value.startdate || this.userForm.value.enddate) {
      this.newFromDate = Math.round(new Date(this.userForm.value.startdate).getTime())
      this.newToDate = Math.round(new Date(this.userForm.value.enddate).getTime())
      let fromDate = Date.parse(this.userForm.value.startdate)
      let toDate = Date.parse(this.userForm.value.enddate)
      let serarchText = this.userForm.value.searchText
      let country = this.userForm.value.country
      let state = this.userForm.value.state
      let status = this.userForm.value.status
      this.service.showSpinner()
      let url = `account/admin/user-management/filter-user-details?page=${this.pageNumber -1}&pageSize=${this.itemPerPage}${(this.userForm.value.startdate.epoc * 1000) ? '&fromDate=' + (this.userForm.value.startdate.epoc * 1000) : ''}${(this.userForm.value.enddate.epoc * 1000) ? '&toDate=' + (this.userForm.value.enddate.epoc * 1000) : ''}${serarchText ? '&search=' + serarchText : ''}${country ? '&country=' + country : ''}${status ? '&status=' + status : ''}${state ? '&state=' + state : ''}`
      this.service.get(url).subscribe((res: any) => {
        this.service.hideSpinner()
        this.userList = res['data']['list'];
        this.totalItems = res['data']['totalCount'] || this.userList.length;

      })
    // } else if (this.userForm.value.searchText) {

    //   this.service.showSpinner()
    //   this.service.get('account/admin/user-management/filter-user-details?search=' + (this.userForm.value.searchText)).subscribe((res: any) => {
    //     this.service.hideSpinner()
    //     if (res.status == 200) {
    //       this.userList = res['data']['list'];
    //       this.totalRecord = res['data'].size
    //       this.totalItems = this.userList.length;
    //     }
    //     else {
    //       this.userList = [];
    //     }
    //   })
    // }
    // else if (this.userForm.value.country) {

    //   this.service.showSpinner()
    //   this.service.get('account/admin/user-management/filter-user-details?country=' + (this.userForm.value.country)).subscribe((res: any) => {
    //     this.service.hideSpinner()
    //     if (res.status == 200) {
    //       this.userList = res['data']['list'];
    //       this.totalItems = res['data']['totalCount'];

    //     }
    //     else {
    //       this.userList = [];
    //     }
    //   })
    // }
    // else if (this.userForm.value.status) {

    //   this.service.showSpinner()
    //   this.service.get('account/admin/user-management/filter-user-details?status=' + (this.userForm.value.status)).subscribe((res: any) => {
    //     this.service.hideSpinner()
    //     if (res.status == 200) {
    //       this.userList = res['data']['list'];
    //       this.totalItems = res['data']['totalCount'];
    //     }
    //     else {
    //       this.userList = [];
    //     }
    //   })
    // }
    // else if (this.userForm.value.country || this.userForm.value.state) {

    //   this.service.showSpinner()
    //   this.service.get('account/admin/user-management/filter-user-details?state=' + (this.userForm.value.state)).subscribe((res: any) => {
    //     this.service.hideSpinner()
    //     if (res.status == 200) {
    //       this.userList = res['data']['list'];
    //       this.totalItems = res['data']['totalCount'];
    //     }
    //     else {
    //       this.userList = [];
    //     }
    //   })
    // }
  }

  formdate() {
    this.fromDate = new Date(this.calender.formdate)
    this.fromDate = this.fromDate.getTime()
    this.IsToDate = false;

  }

  goAdmin() {
    this.router.navigate(['/admin-management'])
  }

  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.userList = this.userList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.userList = this.userList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  validateForm() {
    if (
      this.userForm.value.searchText ||
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.sedan ||
      this.userForm.value.hatch ||
      this.userForm.value.suv ||
      this.userForm.value.country ||  this.userForm.value.state || 
      this.userForm.value.status)
    {
     return false
    }
    else {
     return true
    }
  }
  getStaticList() {
    this.service.get('account/user-count-details').subscribe(res => {
      this.userTotalCount = res['data']
    })
  }

  referalCountLevelOne(){
    let url = `account/my-refered-list?level=1&page=0&pageSize=${this.itemPerPage}`
    this.service.get(url).subscribe((res: any) => {
      this.referalDataLevel = res['data']['referredUsers'][0] ? res['data']['referredUsers'][0] : ''
      console.log(this.referalDataLevel.fromUniqueName);
      
    })
  }
  userId:any
closefa(id){
this.userId = id
$('#skip2fa').modal('show');
}
completeSkipfa() {
  var url = `account/admin/user-management/twoFa-status-change?userId=${this.userId}`;
  this.service.showSpinner();
  this.service.put(url, '').subscribe(res => {

    this.service.hideSpinner();
    if (res['status'] == 200) {
        $('#skip2fa').modal('hide');
        this.service.toasterSucc(res['message']);

      this.getUserList();
    }
  }, err => {

    this.service.hideSpinner();
    if (err['status'] == '401') {
      this.service.onLogout();
      this.service.toasterErr('Unauthorized Access');
    } else {
      this.service.toasterErr('Please wait for few minutes & reload the site.');
    }
  })
}
setPageSizeOptions(setPageSizeOptionsInput: string) {


  if (setPageSizeOptionsInput) {
    this.pageSizeOptions = setPageSizeOptionsInput.split(',').map(str => +str);
  }
}
changePage(event) {
  this.pageNumber = this.pageEvent.pageIndex
  this.itemPerPage = this.pageEvent.pageSize
  this.getUserList()
}
}

