import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { IMyDpOptions } from "mydatepicker";
declare var $
@Component({
  selector: 'app-edit-staff',
  templateUrl: './edit-staff.component.html',
  styleUrls: ['./edit-staff.component.css']
})
export class EditStaffComponent implements OnInit {
  editStaffForm: FormGroup;
  id: any;
  staffDetails: any;
  ipAddress: any;
  staffPrivileges: any = [];
  newArr: any = [];
  checkedBoolead: boolean = true;
  countryList: any = [];
  stateList: any = [];
  countryCode: any;
  CountryCodeList: any;
  products: any = [];
  arr: any = [];

  editImage: any;
  imageData: any = "assets/img/profile-img.jpg";
  type_pass: any = "password";
  type_pass2: any = "password";
  today = new Date();
  eighteenYearsAgoAge = this.today.setFullYear(this.today.getFullYear() - 18);
  privilage = [
    {
      name: 'Dashboard',
      key: 'DASHBOARD'
    },
    {
      name: 'User Management',
      key: 'USER_MANAGEMENT'
    },
    {
      name: 'Sub Admin Management',
      key: 'STAFF_MANAGEMENT'
    },
    {
      name: 'Wallet Management',
      key: 'WALLET_MANAGEMENT'
    },
    {
      name: 'Hot Wallet Management',
      key: 'HOT_COLD_LIMIT_MANAGEMENT'
    },
    {
      name: 'KYC Management',
      key: 'KYC_MANAGEMENT'
    },
    {
      name: 'FD Management',
      key: 'FD_MANAGEMENT'
    },
    {
      name: 'Instant Swap Management',
      key: 'INSTANT_SWAP_MANAGEMENT'
    },
    {
      name: 'Instant Buy/Sell Management',
      key: 'INSTANT_BUY_SELL_MANAGEMENT'
    },
    {
      name: 'AIP Management',
      key: 'AIP_MANAGEMENT'
    },
    {
      name: 'Fee/Coin Management',
      key: 'FEE_MANAGEMENT'
    },
    {
      name: 'Fund Management',
      key: 'FUND_MANAGEMENT'
    },
    // {
    //   name: 'TRX Management',
    //   key: 'TRX_MANAGEMENT'
    // },
    {
      name: 'Settlement Management',
      key: 'SETTLEMENT_MANAGEMENT'
    },
    {
      name: 'Referral Management',
      key: 'REFERRAL_MANAGEMENT'
    },
    {
      name: 'Commission Management',
      key: 'COMMISSION_MANAGEMENT'
    },
    {
      name: 'Ticket Management',
      key: 'TICKET_MANAGEMENT'
    },
    {
      name: 'Bank Management',
      key: 'BANK_MANAGEMENT'
    },
    {
      name: 'TDS Management',
      key: 'TDS_MANAGEMENT'
    },
    {
      name: 'Static Content Management',
      key: 'STATIC_CONTENT'
    },
    {
      name: 'Academy Management',
      key: 'ACADEMY_MANAGEMENT'
    },
    {
      name: 'FAQ Management',
      key: 'FAQ_MANAGEMENT'
    },
    {
      name: 'Logs Management',
      key: 'LOGS_MANAGEMENT'
    },
    {
      name: 'Teams Management',
      key: 'TEAMS_MANAGEMENT'
    },
    {
      name: 'News Management',
      key: 'NEWS_MANAGEMENT'
    },
    {
      name: 'Setting Management',
      key: 'SETTINGS'
    },
    {
      name: 'Trading History',
      key: 'TRADE_HISTORY'
    }
  ]
  constructor(public router: Router, public param: ActivatedRoute, public service: ServiceService, public http: HttpClient) {
    this.param.queryParams.subscribe((res) => {
      this.id = res.id;
    });
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        this.ipAddress = data
      })
  }


  ngOnInit() {
    this.editStaffForm = new FormGroup({
      firstName: new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      lastName: new FormControl('', [Validators.pattern(/^[a-z,.'-]+$/i), Validators.required]),
      email: new FormControl('', [Validators.required, Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,10}|[0-9]{1,3})(\]?)$/i)]),
      gender: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', [Validators.required, Validators.pattern(/^[1-9][0-9]{8,13}$/), Validators.maxLength(18)]),
      dob: new FormControl("", Validators.required),
      selectCountry: new FormControl("", Validators.required),
      selectState: new FormControl(""),
      selectCity: new FormControl("", [Validators.required]),
      zipCode: new FormControl("", Validators.required),
      address: new FormControl("", [
        Validators.maxLength(256),
        Validators.required,
      ]),
      countryCode: new FormControl("+91", [Validators.required]),

    });

    this.onDateChanged();
    this.countryList = this.service.countryListJson;
    this.http.get("assets/contry.json").subscribe((data) => {
      this.products = data;
    });
    this.getProfile();
  }

  getStateList(countryCode) {
    var stateList = this.countryList.filter((x) => x.country == countryCode);
    if (stateList.length) {
      this.stateList = stateList[0].states ? stateList[0].states : '';
      this.countryCode = stateList[0].code ? stateList[0].code : '';
      this.editStaffForm.patchValue({
        'countryCode': this.countryCode
      })
    }
  }
  getStateListwithphoneCode(countryCode) {
    var stateList = this.countryList.filter((x) => x.code == countryCode);
    if (stateList.length) {
      this.stateList = stateList[0].states ? stateList[0].states : '';
      this.countryCode = stateList[0].code ? stateList[0].code : '';
    }
  }

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: "yyyy-mm-dd",
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 },
    height: "33px",
  };
  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear() - 18,
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.myDatePickerOptions = copy1;
  }
 
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }
  getProfile() {
    this.service.showSpinner();
    let dataa = {
      "primaryIdCommonPerRequest": this.id
    }
    this.service.post('account/admin/user-management/get-staff-user-profile', dataa).subscribe((res) => {
      this.staffDetails = res['data']['staffDetails'];
      this.staffPrivileges = res['data']['staffPrivileges'];

      let index = this.arr.findIndex(
        (x) => x.country == this.staffDetails.countryCode
      );

      this.service.name = this.staffDetails.firstName;

      this.staffDetails.country ? this.getStateListwithphoneCode(this.staffDetails.countryCode) : null || "string";
      setTimeout(() => {
        this.editStaffForm.patchValue({
          firstName: this.staffDetails.firstName,
          lastName: this.staffDetails.lastName,
          email: this.staffDetails.email,
          gender: this.staffDetails.gender,
          role: this.staffDetails.role,
          phoneNumber: this.staffDetails.phoneNo,
          selectCountry: this.staffDetails.country,
          selectState: this.staffDetails.state,
          dob: this.staffDetails.dob,
          countryCode: this.staffDetails.countryCode,
          selectCity: this.staffDetails.city,
          address: this.staffDetails.address,
          zipCode: this.staffDetails.zip
        })
        this.service.hideSpinner();
      }, 1000);
      this.editImage = this.staffDetails.imageUrl;

    })
  }

  editStaff() {
    let data = {
      "address": this.editStaffForm.value.address,
      "city": this.editStaffForm.value.selectCity,
      "country": this.editStaffForm.value.selectCountry,
      "countryCode": this.editStaffForm.value.countryCode,
      "dob": this.editStaffForm.value.dob,
      "email": this.editStaffForm.value.email,
      "firstName": this.editStaffForm.value.firstName,
      "gender": this.editStaffForm.value.gender,
      "imageUrl": this.editImage ? this.editImage : this.staffDetails.imageUrl,
      "ipAddress": this.ipAddress.ip,
      "lastName": this.editStaffForm.value.lastName,
      "location": "string",
      "phoneNo": this.editStaffForm.value.phoneNumber,
      "previlage": this.staffPrivileges,
      "roleStatus": this.editStaffForm.value.role,
      'zipCode': this.editStaffForm.value.zipCode,

      "state": this.editStaffForm.value.selectState,
      "webUrl": "string",
      "userIdToUpdate": this.id
    }
    this.service.showSpinner()
    this.service.post('account/admin/user-management/edit-sub-admin', data).subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.service.toasterSucc(res['message'])
        this.router.navigate(['/staff-management'])
      }
    })
  }

  
  checkboxClick(value, checked) {
    if (checked == true) {
      this.staffPrivileges.push(value);
    } else {
      let index = this.newArr.findIndex(x => x == value)
      this.staffPrivileges.splice(index, 1)
    }
  }
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append("file", img);
    this.service.showSpinner();
    this.service.postApi("account/upload-file", fb).subscribe(
      (res) => {
        if (res["status"] == "200") {
          this.editImage = res["data"];
          this.service.hideSpinner();
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Please wait for few minutes & reload the site.");
        }
      }
    );
  }

  switchEye() {
    this.type_pass = "text";
  }

  switchToText() {
    this.type_pass = "password";
  }
  switchEye2() {
    this.type_pass2 = "text";
  }

  switchToText2() {
    this.type_pass2 = "password";
  }
}
