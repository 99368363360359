import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any

@Component({
  selector: 'app-list-aip-percentage',
  templateUrl: './list-aip-percentage.component.html',
  styleUrls: ['./list-aip-percentage.component.css']
})
export class ListAipPercentageComponent implements OnInit {

  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 100;
  currentPage = 1;
  totalItems: any;
  bannerId: any;
  careerStatus: string;
  aipPerList: any = []
  contentId: any;
  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.aipPerPlan();
    this.searchFormValidation();
    this.getEmbellishmentList();

  }

  aipPerPlan() {
    this.commonService.showSpinner();
    this.commonService.get("wallet/view-auto-invest-full-details").subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.aipPerList = res.data;
          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
        }
      },
      (error) => {
        this.commonService.hideSpinner();
      }
    );
  }


  openModal(careerStatus, contentId) {
    this.contentId = contentId;
    this.careerStatus = careerStatus;
    if (careerStatus == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getEmbellishmentList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getEmbellishmentList()
    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  getEmbellishmentList() {
    let apiReqUrl: any = `static/admin/static-content/get-Blog-list?page=0&pageSize=${this.itemPerPage}`

    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data.list ? res.data.list : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }

    })
  }

  pagination(event) {
    this.currentPage = event;
    this.getEmbellishmentList()
  }

  addBody(planId) {
    this.router.navigate(['/add-aip-percent'], { queryParams: { planId: planId } })
  }
  viewBody(contentId) {
    this.router.navigate(['/view-blog'], { queryParams: { contentId: contentId } })
  }
  editBody(contentId) {
    this.router.navigate(['/edit-blog'], { queryParams: { contentId: contentId } })
  }
  deleteCategoryModal(contentId) {
    $('#deleteCategory').modal('show')
    this.contentId = contentId
  }
  deleteCategory() {
    let apiReqUrl: any = "static/admin/static-content/delete-Blog-by-id?blogId=" + this.contentId
    this.commonService.showSpinner();
    this.commonService.post(apiReqUrl, {}).subscribe((res: any) => {
      $('#deleteCategory').modal('hide');
      if (res.status == 200) {
        this.getEmbellishmentList()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }

}
