import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-add-academy-category',
  templateUrl: './add-academy-category.component.html',
  styleUrls: ['./add-academy-category.component.css']
})
export class AddAcademyCategoryComponent implements OnInit {
  addForm: FormGroup;

  constructor(public service: ServiceService, public route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.addFormValidation()
  }

  addFormValidation() {
    this.addForm = new FormGroup({
      'categoryName': new FormControl('', [Validators.required])
    })
  }
  submitForm() {
    var apiReq = {
      "academiccCategory": this.addForm.value.categoryName,
    }
    this.service.showSpinner();
    this.service.post('static/add-academy-category', apiReq).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc(res.message)
        this.router.navigate(['/list-academy-category'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err: any) => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
}
