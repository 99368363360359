import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-view-staff',
  templateUrl: './view-staff.component.html',
  styleUrls: ['./view-staff.component.css']
})
export class ViewStaffComponent implements OnInit {
  id: any;
  staffDetails: any;
  editStaffForm: FormGroup;
  ipAddress: any;
  staffPrivileges: any = [];
  newArr: any = [];
  checkedBoolead: boolean = true;

  constructor(public router: Router, public param: ActivatedRoute, public service: ServiceService, public http: HttpClient) {
    this.param.queryParams.subscribe((res) => {
      this.id = res.id;
    });
    this.http.get<{ ip: string }>('https://jsonip.com')
      .subscribe(data => {
        this.ipAddress = data
      })
  }
  privilage = [
    {
      name: 'Dashboard',
      key: 'DASHBOARD'
    },
    {
      name: 'User Management',
      key: 'USER_MANAGEMENT'
    },
    {
      name: 'Sub Admin Management',
      key: 'STAFF_MANAGEMENT'
    },
    {
      name: 'Wallet Management',
      key: 'WALLET_MANAGEMENT'
    },
    {
      name: 'Hot Wallet Management',
      key: 'HOT_COLD_LIMIT_MANAGEMENT'
    },
    {
      name: 'KYC Management',
      key: 'KYC_MANAGEMENT'
    },
    {
      name: 'FD Management',
      key: 'FD_MANAGEMENT'
    },
    {
      name: 'Instant Swap Management',
      key: 'INSTANT_SWAP_MANAGEMENT'
    },
    {
      name: 'Instant Buy/Sell Management',
      key: 'INSTANT_BUY_SELL_MANAGEMENT'
    },
    {
      name: 'AIP Management',
      key: 'AIP_MANAGEMENT'
    },
    {
      name: 'Fee Management',
      key: 'FEE_MANAGEMENT'
    },
    {
      name: 'Fund Management',
      key: 'FUND_MANAGEMENT'
    },
    // {
    //   name: 'TRX Management',
    //   key: 'TRX_MANAGEMENT'
    // },
    {
      name: 'Settlement Management',
      key: 'SETTLEMENT_MANAGEMENT'
    },
    {
      name: 'Referal Management',
      key: 'REFERRAL_MANAGEMENT'
    },
    {
      name: 'Commission Management',
      key: 'COMMISSION_MANAGEMENT'
    },
    {
      name: 'Ticket Management',
      key: 'TICKET_MANAGEMENT'
    },
    {
      name: 'Bank Management',
      key: 'BANK_MANAGEMENT'
    },
    {
      name: 'TDS Management',
      key: 'TDS_MANAGEMENT'
    },
    {
      name: 'Static Content Management',
      key: 'STATIC_CONTENT'
    },
    {
      name: 'Academy Management',
      key: 'ACADEMY_MANAGEMENT'
    },
    {
      name: 'Faq Management',
      key: 'FAQ_MANAGEMENT'
    },
    {
      name: 'Logs Management',
      key: 'LOGS_MANAGEMENT'
    },
    {
      name: 'Teams Management',
      key: 'TEAMS_MANAGEMENT'
    },
    {
      name: 'News Management',
      key: 'NEWS_MANAGEMENT'
    },
    {
      name: 'Setting Management',
      key: 'SETTINGS'
    },
    {
      name: 'Trading History',
      key: 'TRADE_HISTORY'
    }
  ]
  ngOnInit() {
    this.editStaffForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      role: new FormControl('', Validators.required),
      phoneNumber: new FormControl('', Validators.required),

    })
    this.getProfile();
  }

  getProfile() {
    this.service.showSpinner();
    let dataa = {
      "primaryIdCommonPerRequest": this.id
    }
    this.service.post('account/admin/user-management/get-staff-user-profile', dataa).subscribe((res) => {
      this.staffDetails = res['data']['staffDetails'];
      this.staffPrivileges = res['data']['staffPrivileges'];

      this.editStaffForm.patchValue({
        firstName: this.staffDetails.firstName,
        lastName: this.staffDetails.lastName,
        email: this.staffDetails.email,
        gender: this.staffDetails.gender,
        role: this.staffDetails.role,
        phoneNumber: this.staffDetails.phoneNo
      })
      setTimeout(() => {
        this.service.hideSpinner();
      }, 2000)

    })
  }

  editStaff() {
    let data = {
      "address": "string",
      "city": "string",
      "country": "INDIA",
      "countryCode": "+91",
      "dob": "string",
      "email": this.editStaffForm.value.email,
      "firstName": this.editStaffForm.value.firstName,
      "gender": this.editStaffForm.value.gender,
      "imageUrl": "string",
      "ipAddress": this.ipAddress.ip,
      "lastName": this.editStaffForm.value.lastName,
      "location": "string",
      "phoneNo": this.editStaffForm.value.phoneNumber,
      "previlage": this.newArr,
      "roleStatus": "SUBADMIN",
      "state": "string",
      "webUrl": "string",
      "userIdToUpdate": this.id
    }
    this.service.post('account/admin/user-management/edit-sub-admin', data).subscribe((res) => {
      this.router.navigate(['/staff-management'])
    })
  }

  
  checkboxClick(value, checked) {
    if (checked == true) {
      this.newArr.push(value);
    } else {
      let index = this.newArr.findIndex(x => x == value)
      this.newArr.splice(index, 1)
    }
  }


  back() {
    this.router.navigate(['/staff-management'])
  }
  edit(id) {
    this.router.navigate(['/edit-staff'], { queryParams: { id } })
  }

}
