import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-tds-management',
  templateUrl: './tds-management.component.html',
  styleUrls: ['./tds-management.component.css']
})
export class TdsManagementComponent implements OnInit {

  userForm: FormGroup;
  ticketList: any = [];
  page: number = 0;
  limit: number = 100;
  itemsPerPage: number = 100;
  referralLimit: any
  totalRecords: any;
  pageNumber: number = 1;
  arr: any = ['RESOLVED', 'INPROGRESS', 'CLOSED', 'CANCELLED']
  updatestatus: string;
  isAssending: boolean = true;
  today: any = new Date().toISOString().slice(0, 16)
  status: any = 'SWAP'
  constructor(private router: Router, public service: ServiceService, private activatedRoute: ActivatedRoute, private datePipe: DatePipe) { }

  ngOnInit() {

    this.userForm = new FormGroup({
      'startdate': new FormControl('', Validators.required),
      'enddate': new FormControl('', Validators.required),
      'searchText': new FormControl(''),
      'userName': new FormControl(''),
      'userEmail': new FormControl(''),
      'status': new FormControl('SWAP'),

    })
    this.getTds();
    this.userTdsList();
  }


  tdsData: any
  addTds() {
    let url = `wallet/wallet/add-tds-percentage?tdsId=1`
    let data = {
      tdsPercentage: this.referralLimit
    }
    this.service.post(url, data).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.referralLimit = ''
        this.getTds();
        this.service.hideSpinner();
        this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }
  planData: any
  getTds() {
    let url = `wallet/wallet/get-tds-percentage`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.tdsData = res['data'][0].tdsPercentage
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }
  usersDetils: any
  total: any
  userTdsList() {
    let from = Date.parse(this.userForm.controls.startdate.value);
    let to = Date.parse(this.userForm.controls.enddate.value);
    let userName =this.userForm.controls.userName.value;
    let userEmail = this.userForm.controls.userEmail.value;
    let url = `wallet/admin/transaction-history/get-all-transaction-history?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}${this.userForm.controls.status.value ? "&txnType=" + this.userForm.controls.status.value : ""}${this.userForm.controls.startdate.value ? "&fromDate=" + from : ""}${this.userForm.controls.enddate.value ? "&toDate=" + to : ""}${this.userForm.controls.userName.value ? "&userName=" + userName : ""}${this.userForm.controls.userEmail.value ? "&userEmail=" + userEmail : ""}`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.usersDetils = res['data']['resultlist']
        this.total = res['data']['totalCount']
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }



  isSearched: boolean = false;

  pagination(page) {
    this.pageNumber = page;
    this.userTdsList();
  }


  reset() {
    if (
      this.userForm.value.startdate ||
      this.userForm.value.enddate ||
      this.userForm.value.searchText ||
      this.userForm.value.status || this.userForm.value.userEmail || this.userForm.value.userName
    ) {
      this.userForm.reset({
        startdate: "",
        enddate: "",
        searchText: "",
        status: "SWAP",
        userEmail: "",
        userName: "",
      });
      this.userTdsList();
    }
  }

  viewTicket() {
    this.router.navigate(['/view-ticket'])
  }
  sortAgent(key) {
    if (this.isAssending) {
      this.usersDetils = this.usersDetils.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.usersDetils = this.usersDetils.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
  getExcel() {
    let from = Date.parse(this.userForm.controls.startdate.value);
    let to = Date.parse(this.userForm.controls.enddate.value);
    let userName = this.userForm.controls.userName.value;
    let userEmail = this.userForm.controls.userEmail.value;
    let apiReqUrl = `wallet/admin/transaction-history/get-all-transaction-history?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}${this.userForm.controls.status.value ? "&txnType=" + this.userForm.controls.status.value : ""}${this.userForm.controls.startdate.value ? "&fromDate=" + from : ""}${this.userForm.controls.enddate.value ? "&toDate=" + to : ""}${this.userForm.controls.userName.value ? "&userName=" + userName : ""}${this.userForm.controls.userEmail.value ? "&userEmail=" + userEmail : ""}`
    this.service.get(apiReqUrl).subscribe((res) => {
      if (res['status'] == 200) {
        this.exportAsXLXS(res['data'].resultlist)
      } else {
        this.usersDetils = []
        this.total = 0
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.toasterErr(err['message'])
    })
  }
  exportAsXLXS(usersDetils) {
    let dataArr = [];
    usersDetils.forEach((element, index) => {
      let temp1:any = ''
      let temp2 = ''
      if(element.txnType =='SWAP'){
temp1 = element.swapDeductAmount
temp2 = element.cancellationFee
      }
      if(element.txnType =='INSTANT_SELL'){
temp1 = element.fromUnit * element.livePrice
temp2 =element.fees
      }
      dataArr.push({
        'S.No': index + 1,
        'Email': element.userEmail ? element.userEmail : '--',
        'Name': element.userName ? element.userName : '--',
        'Transaction Type': element.txnType ? element.txnType : '--',
        // 'Type': element.deposit ? element.deposit : '--',
        'Trx ID': element.txnId ? element.txnId : '--',
        // 'From': element.coinType ? element.coinType : '--',
        'From Coin': element.coinType ? element.coinType : '--', 
        'Swap Coin': element.swapType== null ? element.fiatName : element.swapType, 
        'From Amount': element.fromUnit ? element.fromUnit : '--',
        'Total Amount': temp1 ? temp1 : '--',
        'Transaction Fee': temp2 ? temp2 : '--',
        'TDS (%)': element.tdsPercentage ? element.tdsPercentage : '--',
        'To Amount': element.coinAmount == null ? element.amount : element.coinAmount || '--',
        // 'TDS (%)': element.txnTime ? this.datePipe.transform(element.txnTime,'medium') : '--',
        'Date & Time': element.txnTime ? this.datePipe.transform(element.txnTime, 'medium') : '--',
        // 'Fee': element.coinAmount ? element.coinAmount : '--',
        // 'Earn Amount': element.finalDeductAmount ? element.finalDeductAmount : '--',
        // 'Status': element.status ? element.status : '--'
      })
    })
    this.service.exportAsExcelFile(dataArr, 'TDS Management')
  }
 
}
