import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-fd-view',
  templateUrl: './fd-view.component.html',
  styleUrls: ['./fd-view.component.css']
})
export class FdViewComponent implements OnInit {
  fixedDepositId: any
  editData: any
  constructor(private router: Router, private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.fixedDepositId = res.fixedDepositId;
    })
    this.fdView()
  }

  back() {
    this.router.navigate(['/fd-list'])
  }


  fdView() {
    this.commonService.showSpinner()
    this.commonService.get("wallet/view-fixed-deposit-by-id?fixedDepositId=" + this.fixedDepositId).subscribe((res: any) => {
      if (res.status = 200) {
        this.editData = res.data[0];
        this.commonService.hideSpinner()
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })

  }
  toFixed(number, toLength: number) {
    return Number(number).toFixed(toLength)
  }
}
