import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { IMyDpOptions, IMyDateModel } from 'mydatepicker';
import { HttpClient } from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-bank-management',
  templateUrl: './bank-management.component.html',
  styleUrls: ['./bank-management.component.css']
})
export class BankManagementComponent implements OnInit {
  userID: any;
  action: any;
  staffList: any = [];
  pageNumber: number = 1;
  staffForm: FormGroup;
  staffLength: any;
  ipAddress: any;
  beDisable: boolean = true;
  bankDetailId: any;
  upiBankTab: any = 'BANK'
  pageNumberupi: any = 1
  totalItems: any

  constructor(public router: Router, public service: ServiceService, public http: HttpClient) {
    this.staffForm = new FormGroup({
      status: new FormControl(''),
      role: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      search: new FormControl(''),
      upiName: new FormControl(''),
      upiIdUser: new FormControl('')
    })
  }

  ngOnInit() {
    this.getStaffList();
    this.UpiList();
    this.getBankCountDetails();
  }

  userdetails(userId, image,data, status) {
    let dataValue = JSON.stringify(data);
    this.router.navigate(['/view-bank-detail-user'], { queryParams: { userId, image, dataValue, status } })
  }
  userdetailss(userId,data, status) {
    let dataValue = JSON.stringify(data);
    this.router.navigate(['/view-bank-detail-user'], { queryParams: { userId, dataValue, status } })
  }

  addStaff() {
    this.router.navigate(['/add-new-staff'])
  }

  editStaff(id) {
    this.router.navigate(['/edit-staff'], { queryParams: { id } })
  }
  openModal(action, userId) {
    this.bankDetailId = userId;
    this.action = action;
    if (action == 'DELETE') {
      $('#deleteModal').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  upiId:any
  actionSt:any
  openModals(action, upiid) {
    this.actionSt = action
    this.upiId = upiid;
    this.action = action;
    if (action == 'REJECTED') {
      $('#reject').modal('show')

    } 
    else {
      $('#approve').modal('show')

    }
  }
approveUpi(){
  let url = `wallet/admin/upi-status-update?upiStatus=APPROVED&userUpiId=${this.upiId}`
  this.service.put(url,{}).subscribe((res) => {
    if(res['status'] == 200){
      $('#approve').modal('hide')
      this.UpiList()
    }
  })
}
rejectUpi(){
  let url = `wallet/admin/upi-status-update?upiStatus=REJECTED&userUpiId=${this.upiId}`
  this.service.put(url,{}).subscribe((res) => {
  if(res['status'] == 200){
    $('#reject').modal('hide')
    this.UpiList()
  }
  })
}
  deleteFunction() {
    $('#deleteModal').modal('hide')
    let data = {
      "ipAddress": this.ipAddress.ip,
      "primaryIdCommonPerRequest": this.userID
    }
    this.service.post('account/admin/user-management/detele-staff', data).subscribe((res) => {
      this.getStaffList();
    })
  }

  changeTab(currtab) {
    this.upiBankTab = currtab
  }

  getStaffList() {
    let data =
    {
      "page": (this.pageNumber - 1),
      "pageSize": "100",
    }

    var url = "account/admin/search-and-filter-bank-account-list";
    this.service.postApi(url, data).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.staffList = res.data.list;
        this.staffLength = res.data.size

      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.hideSpinner();
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  search() {

    if (this.staffForm.value.toDate) {
      this.service.showSpinner();
      let data = {
        "fromDate": Math.round(new Date(this.staffForm.value.fromDate).getTime()),
        "page": this.pageNumber - 1,
        "pageSize": "100",
        "toDate": Math.round(new Date(this.staffForm.value.toDate).getTime())
      }
      var url = "account/admin/search-and-filter-bank-account-list";
      this.service.post(url, data).subscribe((res) => {
        if (res['status'] == 200) {
          this.staffList = res['data']['list'];
          this.staffLength = res[';']['size'];
          this.service.hideSpinner();
        }
        else {
          this.staffList = [];
          this.service.hideSpinner();
        }

      })
    }
    else if (this.staffForm.value.search) {
      this.service.showSpinner();
      let data = {
        "page": this.pageNumber - 1,
        "pageSize": "100",
        "search": this.staffForm.value.search,
      }
      var url = "account/admin/search-and-filter-bank-account-list";
      this.service.post(url, data).subscribe((res: any) => {
        this.staffList = null;
        if (res.status == 200) {
          this.staffList = res['data']['list'];
          this.staffLength = res.data.size;
          this.service.hideSpinner();
        }
        else {
          this.staffList = [];
          this.service.hideSpinner();
        }
      })
    }
    else if (this.staffForm.value.role) {
      this.service.showSpinner();
      let data = {
        "page": this.pageNumber - 1,
        "pageSize": "100",
        "role": this.staffForm.value.role,
      }
      var url = "account/admin/search-and-filter-bank-account-list";
      this.service.post(url, data).subscribe((res: any) => {
        this.staffList = null;
        if (res.status == 200) {
          this.staffList = res['data']['list'];
          this.staffLength = res.data.size;
          this.service.hideSpinner();
        }
        else {
          this.staffList = [];
          this.service.hideSpinner();
        }
      })
    }
    else if (this.staffForm.value.status) {
      this.service.showSpinner();
      let data = {
        "page": this.pageNumber - 1,
        "pageSize": "100",
        "bankStatus": this.staffForm.value.status,
      }
      var url = "account/admin/search-and-filter-bank-account-list";
      this.service.post(url, data).subscribe((res: any) => {
        this.staffList = null;
        if (res.status == 200) {
          this.staffList = res['data']['list'];
          this.staffLength = res.data.size;
          this.service.hideSpinner();
        }
        else {
          this.staffList = [];
          this.service.hideSpinner();
        }
      })
    }
    else if (this.staffForm.invalid) {
      this.service.showSpinner();
      this.getStaffList();
      this.service.hideSpinner();
    }
  }

  viewStaff(id) {
    this.router.navigate(['/view-staff'], { queryParams: { id } })
  }

  performAction() {
    $('#block').modal('hide')
    var url = 'account/admin/approve-or-reject-bank-account?bankDetailId=' + (this.bankDetailId) + '&bankStatus=' + ('REJECTED');
    this.service.post(url, '').subscribe((res) => {
      this.service.toasterSucc('Rejected')
      this.getStaffList();
    })
  }

  performActionActive() {
    $('#active').modal('hide')
    var url = 'account/admin/approve-or-reject-bank-account?bankDetailId=' + (this.bankDetailId) + '&bankStatus=' + ('APPROVED');
    this.service.post(url, '').subscribe((res) => {
      this.service.toasterSucc('Approved')
      this.getStaffList();
    })
  }

  reset() {
    this.staffForm.reset();
    this.getStaffList();
    this.UpiList()
    this.staffForm.get('status').setValue('All')
  }

  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  public toDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };

  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }

  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }
  public onChange(event: IMyDateModel) {
    if (event.formatted) {
      this.beDisable = false
      let d: Date = new Date(event.jsdate.getTime());
      d.setDate(d.getDate() - 1);
      let copy: IMyDpOptions = this.getCopyOfToDateOpt();
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.toDate = copy;
    }

  }
  getCopyOfToDateOpt(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toDate));
  }

  exportAsXLSX() {
    let dataArr = [];
    this.staffList.forEach((element, ind) => {
      dataArr.push({
        "ID": ind + 1,
        "Name": element.firstName + '' + element.lastName ? element.lastName : '',
        "Role": element.role ? element.role : '--',
        "Created At": element.createdTime ? element.createdTime.slice(0, 10) : '--',
      })
    })
    this.service.exportAsExcelFile(dataArr, 'Staff_list');
  }
  pagination(page) {

    this.pageNumber = page
    this.getStaffList()
  }
  upiList: any = [];
  upiCount: any
  UpiList() {
    let url = `wallet/admin/User-Upi-List?page=${this.pageNumberupi - 1}&pageSize=100`
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.upiList = res.data.List.content;
        this.upiCount = res.data.Count
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.hideSpinner();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.hideSpinner();
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  serachUpiId() {
    let url = `wallet/admin/User-Upi-List?name=${this.staffForm.value.upiName}&page=${this.pageNumberupi - 1}&pageSize=100&upiId=${this.staffForm.value.upiIdUser}`
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.upiList = res.data.List.content || res.data.List || '';
        this.upiCount = res.data.Count
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.hideSpinner();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.hideSpinner();
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  resetUpi() {
    if (
      this.staffForm.value.upiName || this.staffForm.value.upiIdUser
    ) {
      this.staffForm.reset({
        upiName: "",
        upiIdUser: "",
      });
      this.UpiList();
    }
  }
  paginations(page) {
    this.pageNumberupi = page
    this.UpiList()
  }
  validateForm() {
    if (
      this.staffForm.value.status ||
      this.staffForm.value.role ||
      this.staffForm.value.fromDate ||
      this.staffForm.value.toDate ||
      this.staffForm.value.search ||
      this.staffForm.value.upiIdUser ||
      this.staffForm.value.upiName || this.staffForm.value.upiIdUser

    ) {
      return false
    }
    else {
      return true
    }

  }

  bankCount: any;
  getBankCountDetails() {
    this.service.get('/account/bank-count-details').subscribe(res => {
      if (res['status'] == 200) {
        this.bankCount = res['data'];
      }
    }, err => {
      this.service.hideSpinner();
    })
  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.staffList = this.staffList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.staffList = this.staffList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
