import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any

@Component({
  selector: 'app-list-announcement-category',
  templateUrl: './list-announcement-category.component.html',
  styleUrls: ['./list-announcement-category.component.css']
})
export class ListAnnouncementCategoryComponent implements OnInit {

  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  announcementId: any;

  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';

  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getEmbellishmentList();
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getEmbellishmentList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getEmbellishmentList()
    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  getEmbellishmentList() {
    let apiReqUrl: any = `static/get-new-announcement-category-list`
    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data ? res.data : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }

    })
  }

  pagination(event) {
    this.currentPage = event;
    this.getEmbellishmentList()
  }

  addBody() {
    this.router.navigate(['/add-announcement-category'])
  }
  viewBody(announcementId) {
    this.router.navigate(['/view-announcement-category'], { queryParams: { announcementId: announcementId } })
  }
  editBody(announcementId) {
    this.router.navigate(['/edit-announcement-category'], { queryParams: { announcementId: announcementId } })
  }
  deleteCategoryModal(announcementId) {
    this.announcementId = announcementId
    this.deleteAlertMessage();
  }

  alertMsg: any
  deleteAlertMessage() {
    $('#deleteCategory').modal('show')
    let apiReqUrl: any = "static/delete-new-alert-category?announcementId=" + this.announcementId
    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.hideSpinner();
        this.alertMsg = res.message
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }

  deleteCategory() {
    let apiReqUrl: any = "static/delete-announcement-new-category?announcementId=" + this.announcementId
    this.commonService.showSpinner();
    this.commonService.delete(apiReqUrl).subscribe((res: any) => {
      $('#deleteCategory').modal('hide');
      if (res.status == 200) {
        this.commonService.hideSpinner();
        this.getEmbellishmentList()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.bodyListArray = this.bodyListArray.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.bodyListArray = this.bodyListArray.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
