import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
declare var $: any;
@Component({
  selector: 'app-instant-buy-sell-management-hitory',
  templateUrl: './instant-buy-sell-management-hitory.component.html',
  styleUrls: ['./instant-buy-sell-management-hitory.component.css']
})
export class InstantBuySellManagementHitoryComponent implements OnInit {

  searchText: string = "";
  pageNumber: number = 1;
  status: string;
  kycList: any = [];
  kycData: any = {};
  userKycId: any;
  kycDetail: any;
  itemsPerPage: any = 100;
  flip: boolean = false;
  countryList: any = [];
  country: any;
  transferForm: FormGroup;
  val: any
  flag: any
  dashStatus: any
  today: any = new Date().toISOString().split('T')[0]
  constructor(public service: ServiceService, public router: Router, public activatedRoute: ActivatedRoute,  private datePipe: DatePipe) {
    this.activatedRoute.queryParams.subscribe(res => {

    })
  }

  ngOnInit() {
    this.checkTransferFormValidations()
    this.instantBuySell();
  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'search': new FormControl(''),
    })
  }

  reset() {
    this.transferForm.reset({
      fromDate: "",
      toDate: "",
      search: "",
    });
    this.instantBuySell();
  }


  gotoview(id) {
    this.router.navigate(["/kyc-action/id"], {
      queryParams: { id: id },
    });
  }

  changePageNumber(page) {
    this.pageNumber = page;
    this.instantBuySell();
  }

  instantBuySellDetail: any
  instantBuySellDetailTotal: any
  instantBuySell() {
    console.table(this.transferForm.value.fromDate, this.transferForm.value.toDate);
    var startdate = this.transferForm.value.fromDate ? (Date.parse(this.transferForm.value.fromDate)) : ''
    var enddate = this.transferForm.value.toDate ? (Date.parse(this.transferForm.value.toDate)) : ''
    this.service.showSpinner();
    this.service.get('wallet/admin/transaction-history/get-all-transaction-history?deposit=INSTANT&page=' + (this.pageNumber - 1) + '&pageSize=' + (100) + '&fromDate=' + startdate + '&toDate=' + enddate + '&userName=' + this.transferForm.value.search).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.kycList = res.data.resultlist;
        this.kycDetail = res.data.totalCount;
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
    })
  }

  disableSearchbtn() {

    if (this.transferForm.value.fromDate || this.transferForm.value.toDate || this.transferForm.value.search) {
      return false
    }
    else {
      return true
    }
  }

  getExcel() {
    console.table(this.transferForm.value.fromDate, this.transferForm.value.toDate);
    var startdate = this.transferForm.value.fromDate ? (Date.parse(this.transferForm.value.fromDate)) : ''
    var enddate = this.transferForm.value.toDate ? (Date.parse(this.transferForm.value.toDate)) : ''
    this.service.get('wallet/admin/transaction-history/get-all-transaction-history?deposit=INSTANT&page=' + (this.pageNumber - 1) + '&pageSize=' + (this.kycDetail) + '&fromDate=' + startdate + '&toDate=' + enddate + '&userName=' + this.transferForm.value.search).subscribe((res: any) => {
      if (res.status == 200) {
        this.exportAsXLSX(res.data.resultlist)
      }
      else {
      }
    }, (error) => {
    })
  }

  exportAsXLSX(resultlist) {
    let dataArr = [];
    resultlist.forEach((element, ind) => {
      if(element.txnType == 'INSTANT_BUY' || element.txnType == 'INSTANT_SELL'){
        dataArr.push({
          // "S no": ind + 1,
          "Username": element.userName ? element.userName : '--',
          "Email": element.userEmail ? element.userEmail : '',
          "Buy Assets": element.coinType ? element.coinType : '--',
          "Sell Assets": element.fiatName ? element.fiatName : '--',
          "Fees": element.fees ? element.fees : '--',
          "TDS": element.tdsPercentage ? element.tdsPercentage : '--',
          // "Coin Amount": element.fromUnit ? element.fromUnit : '--',
          // "Coin Amount": element.coinAmount ? element.coinAmount : '--',
          // "Fiat Amount": element.fromUnit ? element.fromUnit : '--',
          "Coin Amount" : (element.fromUnit * element.livePrice)  ?  (element.fromUnit * element.livePrice)  : '--',
          "Base Coin Amount " : (element.fromUnit / element.livePrice) ? (element.fromUnit / element.livePrice) : '--',
          "Transfer Amount" : element.amount ? element.amount : '--',
          "Transaction ID": element.txnId ? element.txnId : '--',
          "Tnx Type": element.txnType ? element.txnType : '--',
          "Trx Date": element.txnTime ? this.datePipe.transform(element.txnTime,'medium') : '--',
          "Status" :  element.status == 'CONFIRM' ? 'CONFIRMED' : element.status
        })
      }
   
    })
    this.service.exportAsExcelFile(dataArr, 'Instant Buy/Sell Management');
    
  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.kycList = this.kycList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.kycList = this.kycList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
