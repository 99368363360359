import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any
@Component({
  selector: 'app-list-announcement',
  templateUrl: './list-announcement.component.html',
  styleUrls: ['./list-announcement.component.css']
})
export class ListAnnouncementComponent implements OnInit {
  calender  = {
    fromdate : '',
    todate : '',
    type : '',
    title : ''
  }

  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  announcementId: any;

  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';

  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.getEmbellishmentList();
  }


  

  selectStatus() {
    this.currentPage = 1
  }
  getEmbellishmentList() {
    let apiReqUrl: any = `static/view-announcement-new-subCategory-list`
    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data ? res.data : [];
        this.filterArray = res.data ? res.data : [];
        this.totalItems = res.data.length
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }

    })
  }
  filterArray : any = []
  search() {
    if(this.calender.title){
      this.filterArray = this.bodyListArray.filter((res)=>{
        let name = String(res.title).toLowerCase()
        return  name.includes(String(this.calender.title).toLowerCase()) 
      })
    }
  
  
    if(this.calender.fromdate){
      this.filterArray = this.filterArray.filter((res)=>{
        let createdAt = Date.parse(new Date(res.creationTime).toISOString().split('T')[0])
        return createdAt >= Date.parse(this.calender.fromdate)
      })
    }
    if(this.calender.todate){
      this.filterArray = this.filterArray.filter((res)=>{
        let createdAt = Date.parse(new Date(res.creationTime).toISOString().split('T')[0])
        return createdAt >= Date.parse(this.calender.fromdate) && createdAt <= Date.parse(this.calender.todate)
      })
    }
    this.totalItems = this.filterArray.length
  }
  reset() {
    this.calender.fromdate = ''
    this.calender.todate = ''
    this.calender.type = ''
    this.calender.title = ''

    this.filterArray = this.bodyListArray
    this.totalItems = this.bodyListArray.length
  }
  pagination(event) {
    this.currentPage = event;
 
  }

  addBody() {
    this.router.navigate(['/add-announcement'])
  }
  viewBody(announcementId) {
    this.router.navigate(['/view-announcement'], { queryParams: { announcementId: announcementId } })
  }

  editBody(announcementId) {
    this.router.navigate(['/edit-announcement'], { queryParams: { announcementId: announcementId } })
  }

  deleteCategoryModal(announcementId) {
    $('#deleteCategory').modal('show')
    this.announcementId = announcementId
  }
  deleteCategory() {
    let apiReqUrl: any = "static/delete-announcement-new-subCategory?announcementId=" + this.announcementId
    this.commonService.showSpinner();
    this.commonService.delete(apiReqUrl).subscribe((res: any) => {
      $('#deleteCategory').modal('hide');
      if (res.status == 200) {
        this.getEmbellishmentList()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  validateForm(){

  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.filterArray = this.filterArray.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.filterArray = this.filterArray.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
