import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-add-submit-requst-category',
  templateUrl: './add-submit-requst-category.component.html',
  styleUrls: ['./add-submit-requst-category.component.css']
})
export class AddSubmitRequstCategoryComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  tab: any = 'announcement';
  countryList: any = [];
  categoryID:any
  country: any;
  generalAnnouncementForm: FormGroup
  constructor(private router: Router, public commonService: ServiceService){
   }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.genaralF()
    this.getCountryList()
  }

  getCountryList() {
    this.commonService.get('account/get-country-list').subscribe((res) => {
      if (res['status'] == 200) {
        this.countryList = res['data']
      }
    })
  }
  select(tab) {
    this.tab = tab
  }

  genaralF() {
    this.generalAnnouncementForm = new FormGroup({
      country: new FormControl('', [Validators.required]),
      event: new FormControl(''),
      user: new FormControl(''),
      generaldescription: new FormControl('', [Validators.required])
    })
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'title': new FormControl('', [Validators.required]),
      'description': new FormControl('', [Validators.required, Validators.maxLength(256)]),
      'image': new FormControl('', [Validators.required])
    })
  }

  addSwatches() {
    let url = `static/admin/add-request-category`
    let data = {
        "category": this.addSwatchesForm.value.title,
        "createdAt": new Date().toISOString(),
        "image": this.imageUrl,
        "title":this.addSwatchesForm.value.description,
        "updatedAt": new Date().toISOString()
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/list-submit-request-category'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

}
