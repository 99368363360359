import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
declare var $
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  ipAddress: any;
  userDetail: any = {};
  editForm: FormGroup;
  editImage: any;
  profileData:any
  emailAuth: any = '';
  smsAuth: any = '';
  googleAuth: any = '';
  constructor(public router: Router, public service: ServiceService) { }

  ngOnInit() {
    this.editForm = new FormGroup({
      'name': new FormControl(''),
      'city': new FormControl(''),
      'country': new FormControl(''),
      'email': new FormControl(''),
      'phone':new FormControl(''),
      'address': new FormControl(''),
      twoFa: new FormControl("")
    })
    this.myProfile();
    this.getIP();
  }
  getIP() {
    this.service.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.IPv4;
    });
  }
  myProfile() {
    var url = 'account/my-account';
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      if (res['status'] == 200) {
        this.userDetail = res['data'];
        this.profileData = res["data"];
        this.editImage = this.userDetail.imageUrl;
        this.editForm.patchValue({
          'name': this.userDetail.firstName,
          'email': this.userDetail.email,
          'phone': this.userDetail.phoneNo,
          'address': this.userDetail.address,
          'city': this.userDetail.city,
          'country': this.userDetail.country,
          twoFa: this.userDetail.twoFaType,
        })
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message']);
      }
    }, err => {
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.editImage = res['data'];
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }


  updateProfile() {
   
    var apiReq = {
      "address": this.editForm.value.address,
      "city": this.editForm.value.city,
      "country": this.editForm.value.country,
      "firstName": this.editForm.value.name,
      "phoneNo": this.editForm.value.phone,
      "gender": this.editForm.value.gender,
      "email": this.editForm.value.email,
      "imageUrl": this.editImage ? this.editImage : this.userDetail.imageUrl
    }
    this.service.showSpinner();
    this.service.post('account/profile-update', apiReq).subscribe(res => {
      if (res['status'] == 200) {
        this.service.changeLoginSub('login')
        this.service.toasterSucc('Profile Updated Successfully');
        this.router.navigate(['/my-profile'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  preventSpace(event) {
    if (event.charCode == 32 && !event.target.value) {
      event.preventDefault()
    }
  }
 // --------------------=================≠≠≠≠≠≠≠≠≠≠≠≠=====≠≠≠≠≠≠≠≠≠≠≠≠≠≠≠===≠≠≠≠≠≠≠≠≠≠≠≠≠≠=====≠≠≠≠≠≠≠≠≠≠≠≠≠≠≠≠≠≠≠≠=================================≠≠≠≠
  // =======≠≠≠≠≠============//////=====    NEW CODE ======----------==========-=-=-=-=-=-=-=-=-

  updateTwoFa(type) {
    if (type == "GOOGLE") {
      this.enableGoogleAuth();
    } else if (type == "EMAIL") {
      this.openEMAILAuthModal();
    } else if (type == "SMS") {
      this.openSMSAuthModal();
    } else if (type == "NONE") {
      this.disableAll();
    }
    else if (type == "SKIP") {
      this.disableAll();
    }
  }
  disableAll() {
    if (this.profileData.twoFaType == "GOOGLE") {
      this.bruteForce = true
    this.openGoogleAuthModal()
    }
    if(this.profileData.twoFaType == "SMS"){
      this.bruteForce = true
      this.openSMSAuthModal()
    }
    if(this.profileData.twoFaType == "EMAIL"){
      this.bruteForce = true
      this.openEMAILAuthModal()
    }
  

  }
  bruteForce : boolean = false
  // Enable Google Auth Functionality
  qrCode: any;
  secretKey: any;
  enableGoogleAuth() {
    if(this.bruteForce){

    }
    else{
      if(this.profileData.twoFaType == 'EMAIL'){
        this.service.toasterErr("Disable Email Auth First");
        return
      }
      if(this.profileData.twoFaType == 'SMS'){
        this.service.toasterErr("Disable SMS Auth First");
        return
      }
      if(this.profileData.twoFaType == 'GOOGLE'){
        return
      }
    }
    this.bruteForce = false
    if (
      this.profileData.twoFaType == "SKIP" ||
      this.profileData.twoFaType == "NONE"
    ) {
      this.service.showSpinner();
      this.service.get("account/google-auth").subscribe(
        (res) => {
          if (res["status"] == 200) {
            this.qrCode = res["data"]["qrCode"];
            this.secretKey = res["data"]["secretKey"];
            $("#googleAuth").modal({ backdrop: "static", keyboard: false });
            this.service.hideSpinner();
          }
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
    } else {
      this.service.toasterErr("Disable SMS Auth First");
    }
  }

  verifyGoogleAuth() {
    var url = "account/verify-google-code";
    var apireq = {
      code: this.googleAuth,
      secretKey: this.secretKey,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.post(url, apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.qrCode = "";
          this.secretKey = "";
          this.googleAuth = "";
          this.myProfile();
          this.service.toasterSucc("Google Auth Enabled Successfully");
          $("#googleAuth").modal("hide");
        } else {
          this.service.toasterErr("Wrong Google Authentication Code");
          this.googleAuth = "";
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr("Something Went Wrong");
      }
    );
  }
  //Disable Google Security
  openGoogleAuthModal() {
    this.googleAuth = "";
    $("#googleAuthDisable").modal({ backdrop: "static" });
  }
  disableTwoFA() {
    var apireq = {
      code: this.googleAuth, //this.authCode,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.post("account/twoFa-disable", apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $("#googleAuthDisable").modal("hide");
          this.myProfile();
          this.googleAuth = "";
          this.service.toasterSucc("Google Auth Disabled Successfully");
        } else {
          this.service.toasterErr("Wrong Google Authentication Code");
          this.googleAuth = "";
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err["message"]);
      }
    );
  }
  // Enable SMS Auth Functionality
  openSMSAuthModal() {
    if(this.bruteForce){

    }
    else{
      if(this.profileData.twoFaType == 'EMAIL'){
        this.service.toasterErr("Disable Email Auth First");
        return
      }
      if(this.profileData.twoFaType == 'GOOGLE'){
        this.service.toasterErr("Disable Google Auth First");
        return
      }
      if(this.profileData.twoFaType == 'SMS'){
        return
      }
    }
    this.bruteForce = false
    this.smsAuth = "";
    if (
      this.profileData.twoFaType != "GOOGLE" ||
      this.profileData.twoFaType != "EMAIL"
    ) {
      this.service.showSpinner();
      this.service.get("account/send-sms-code").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal({ backdrop: "static" });
            this.service.toasterSucc(res["message"]);
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.toasterErr("Mobile Number Not Verified");
          } else {
            this.service.toasterErr(err["message"]);
          }
        }
      );
    } else {
      this.service.toasterErr("Disable Google Auth First");
    }
  }

  verifySMSAuth() {
    var url = "account/verify-sms-code";
    var smsapireq = {
      otp: this.smsAuth,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.post(url, smsapireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.smsAuth = "";
          this.myProfile();
          this.service.toasterSucc(res["message"]);
          $("#smsAuth").modal("hide");
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr("Something Went Wrong");
      }
    );
  }

  // Disable SMS Auth Functionality
  disableSMSAuth() {
    var apireq = {
      otp: this.smsAuth, //this.authCode,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.post("account/sms-auth-disable", apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $("#smsAuth").modal("hide");
          this.myProfile();
          this.smsAuth = "";
          $("#smsAuth").modal("hide");
          this.service.toasterSucc("SMS Auth Disabled Successfully");
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err["message"]);
      }
    );
  }

  // email enable authentication
  openEMAILAuthModal() {
    if(this.bruteForce){
    }
    else{
      if(this.profileData.twoFaType == 'SMS'){
        this.service.toasterErr("Disable SMS Auth First");
        return
      }
      if(this.profileData.twoFaType == 'GOOGLE'){
        this.service.toasterErr("Disable Google Auth First");
        return
      }
      if(this.profileData.twoFaType == 'EMAIL'){
        return
      }
    }
    this.bruteForce = false
    this.emailAuth = "";
    if (
      this.profileData.twoFaType != "GOOGLE" ||
      this.profileData.twoFaType != "SMS"
    ) {
      this.service.showSpinner();
      this.service.get("account/resend-verify-otp").subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal({ backdrop: "static" });
            this.service.toasterSucc(res["message"]);
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          if (err["status"] == "500") {
            this.service.toasterErr("Email Address Not Verified");
          } else {
            this.service.toasterErr(err["message"]);
          }
        }
      );
    } else {
      this.service.toasterErr(
        "Disable Google Auth First" || "Disable SMS Auth First"
      );
    }
  }

  // verify email authenicator
  verifyEmailAuth() {
    var url = "account/verify-Email-Sms-code";
    var smsapireq = {
      emailOtp: this.emailAuth,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.post(url, smsapireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.emailAuth = "";
          this.myProfile();
          this.service.toasterSucc("Email Auth Enable Successfully");
          $("#emailAuth").modal("hide");
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr("Something Went Wrong");
      }
    );
  }
  // end email authenticator
  // Disable email Auth Functionality
  disableEmailAuth() {
    var apireq = {
      emailOtp: this.emailAuth, //this.authCode,
      ipAddress: this.ipAddress,
      source: "WEB",
    };
    this.service.showSpinner();
    this.service.post("account/email-sms-auth-disable", apireq).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $("#emailAuth").modal("hide");
          this.myProfile();
          this.smsAuth = "";
          $("#emailAuth").modal("hide");
          this.service.toasterSucc("Email Auth Disabled Successfully");
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr(err["message"]);
      }
    );
  }
}
