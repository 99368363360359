import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
declare var $: any
@Component({
  selector: 'app-referral-history',
  templateUrl: './referral-history.component.html',
  styleUrls: ['./referral-history.component.css']
})
export class ReferralHistoryComponent implements OnInit {
  transferForm: FormGroup;
  referalSerach: FormGroup;
  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  itemsPerPages = 100;
  currentPages: number = 1;
  announcementId: any;
  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';
  currTab = 'SIGNUP';
  fixedD: any = 'FIXED DEPOSIT'
  referalPage: any = 1
  referalPageSize: any = 100
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.checkTransferFormValidations();
    this.searchReferalFun()
    this.getEmbellishmentList();
    this.levelList()
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchReferalFun(){
    this.referalSerach = new FormGroup({
      level : new FormControl(''),
      email : new FormControl(''),
      uniqName : new FormControl(''),
    })
  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'currency': new FormControl(''),
      // 'type': new FormControl(''),
      // 'searchTextTxnHash': new FormControl(''),
      'amount': new FormControl(''),
      'coinNameSe': new FormControl(''),
      'status': new FormControl('')
    })
  }
  validateForm() {
    if (
      this.transferForm.value.status ||
      this.transferForm.value.currency ||
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      // this.transferForm.value.type ||
      this.transferForm.value.amount ||
      // this.transferForm.value.searchTextTxnHash ||
      this.transferForm.value.coinNameSe
    ) {

      return false
    }
    else {
      return true
    }

  }
  validateFormNew() {
    if (
      this.referalSerach.value.email ||
      this.referalSerach.value.level ||
      this.referalSerach.value.uniqName
    ) {

      return false
    }
    else {
      return true
    }

  }
  forSerachValue() {
    let fromDate = Date.parse(this.transferForm.value.fromDate);
    let toDate = Date.parse(this.transferForm.value.toDate);
    let currency = this.transferForm.value.currency;
    // let type = this.transferForm.value.type;
    // let searchTextTxnHash = this.transferForm.value.searchTextTxnHash;
    let amount = this.transferForm.value.amount;
    let coinNameSe = this.transferForm.value.coinNameSe;
    let status = this.transferForm.value.status
    let url
    if (this.currTab == 'FD') {
      url = `wallet/admin/transaction-history/get-all-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.coinNameSe ? "&coinName=" + coinNameSe : ""}${this.transferForm.value.amount ? "&amount=" + amount : ""}${this.transferForm.value.status ? "&status=" + status : ""}&deposit=REFERAL&txnType=${this.fixedD}`
    } else {
      url = `wallet/admin/transaction-history/get-all-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.coinNameSe ? "&coinName=" + coinNameSe : ""}${this.transferForm.value.amount ? "&amount=" + amount : ""}${this.transferForm.value.status ? "&status=" + status : ""}&deposit=REFERAL&txnType=TRADE`
    }
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.commonService.hideSpinner();
          this.loopData = res.data.resultlist;
          this.lengthTotal = res['data'].totalCount
        } else {
          this.loopData = []
        }
      },
      (err: any) => {
        this.loopData = []
        this.commonService.hideSpinner();
        this.commonService.toasterErr(err.error.message);
      }
    );
  }

  resetfun() {
    if (
      this.referalSerach.value.email || this.referalSerach.value.level ||
      this.referalSerach.value.uniqName 
    ) {
      this.referalSerach.reset({
        uniqName: "",
        email: "",
        level: ""
      });
      if (this.currTab == 'FD') {
        this.getAllTransactionHistoryFixedDeposit('FIXED DEPOSIT');
      }else if(this.currTab =='SIGNUP'){
        this.getEmbellishmentList()
      }
       else {
        this.getAllTransactionHistoryFixedDeposit('TRADE');
      }
    }
  }
  reset() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      // this.transferForm.value.type ||
      // this.transferForm.value.searchTextTxnHash ||
      this.transferForm.value.amount ||
      this.transferForm.value.coinNameSe ||
      this.transferForm.value.status

    ) {
      this.transferForm.reset({
        fromDate: "",
        toDate: "",
        // type: "",
        // searchTextTxnHash: "",
        amount: "",
        coinNameSe: "",
        status: '',


      });
      if (this.currTab == 'FD') {
        this.getAllTransactionHistoryFixedDeposit('FIXED DEPOSIT');
      }else if(this.currTab =='SIGNUP'){
        this.getEmbellishmentList()
      } 
      else {
        this.getAllTransactionHistoryFixedDeposit('TRADE');
      }
    }
  }
  selectTab(tab) {
    this.currTab = tab;
    if (tab == 'SIGNUP') {
      this.getEmbellishmentList();
      // this.coinListArr = [];
      // this.getCoinWalletList()
      // this.finalHotWalletArray = []
      // this.getOtherList()
    } else if (tab == 'FD') {
      this.getAllTransactionHistoryFixedDeposit('FIXED DEPOSIT');
    } else {
      this.getAllTransactionHistoryFixedDeposit('TRADE');

    }
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getEmbellishmentList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getEmbellishmentList()
    }
  }

  selectStatus() {
    this.currentPage = 1
  }

  totalItemsreferal:any
  getEmbellishmentList() {
    let apiReqUrl: any = `account/admin/user-management/referal-list?page=${this.referalPage - 1}&pageSize=${this.referalPageSize}`
    // this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data.list;
        this.totalItemsreferal = res.data.count
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItemsreferal = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.bodyListArray = []
        this.totalItemsreferal = 0
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
      }

    })
  }

  pagination(event) {
    this.currentPage = event;
    this.getEmbellishmentList()
  }

  addBody() {
    this.router.navigate(['/referal-add'])
  }

  viewBody(announcementId) {
    this.router.navigate(['/view-announcement'], { queryParams: { announcementId: announcementId } })
  }


  editBody(announcementId) {
    this.router.navigate(['/edit-announcement'], { queryParams: { announcementId: announcementId } })
  }

  getRefList() {
    let url = `account/Referal-List`
    this.commonService.get(url).subscribe((res) => {
      if (res['status'] == 200) {

      }
    })
  }
  loopData: any = []
  lengthTotal: any
  itemsPerPage: any = 100
  getAllTransactionHistoryFixedDeposit(status) {
    let url = `wallet/admin/transaction-history/get-all-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&deposit=REFERAL&txnType=${status}`
    this.commonService.showSpinner()
    this.commonService.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.commonService.hideSpinner()
        this.loopData = res.data.resultlist;
        this.lengthTotal = res['data'].totalCount
      } else {
        this.loopData = []
      }


    }, err => {
      this.commonService.hideSpinner();
      this.loopData = []
      if (err.status >= 400) {
        this.commonService.toasterErr(err.message)
      }
    })
  }

  paginations(page) {
    this.currentPages = page
    if (this.currTab == "FD") {
      this.getAllTransactionHistoryFixedDeposit('FIXED DEPOSIT');
    } else if (this.currTab == "TRADE") {
      this.getAllTransactionHistoryFixedDeposit('TRADE');
    }
    else {
      this.getEmbellishmentList()
    }

  }

  searchFilter() {
    let email = this.referalSerach.value.email
    let uniqName = this.referalSerach.value.uniqName
    let level = this.referalSerach.value.level
    let url = `account/admin/user-management/referal-list?page=${this.referalPage - 1}&pageSize=${this.referalPageSize}${this.referalSerach.value.email ? "&email=" + email : ""}${this.referalSerach.value.level ? "&level=" + level : ""}${this.referalSerach.value.uniqName ? "&uniqName=" + uniqName : ""}`
    this.commonService.showSpinner()
    this.commonService.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.commonService.hideSpinner()
        this.bodyListArray = res.data.list;
        this.totalItemsreferal = res.data.count
      } else {
      this.commonService.hideSpinner();
        this.bodyListArray = []
      }
    }, err => {
      this.commonService.hideSpinner();
      this.bodyListArray = []
      if (err.status >= 400) {
        this.commonService.toasterErr(err.message)
      }
    })
  }
  levelData: any = []
  levelList() {
    this.levelData = []
    for (let i = 1; i < 11; i++) {
      this.levelData.push(i)
    }
    this.levelData
  }
  paginationReferal(page){
this.referalPage = page
this.getEmbellishmentList()
  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.bodyListArray = this.bodyListArray.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.bodyListArray = this.bodyListArray.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
  sortAgentloopData(key) {
    if (this.isAssending) {
      this.loopData = this.loopData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.loopData = this.loopData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
  sortAgentloopDataFd(key) {
    if (this.isAssending) {
      this.loopData = this.loopData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.loopData = this.loopData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
