import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $: any;

@Component({
  selector: 'app-list-blog',
  templateUrl: './list-blog.component.html',
  styleUrls: ['./list-blog.component.css']
})
export class ListBlogComponent implements OnInit {
  calender  = {
    fromdate : '',
    todate : '',
    type : '',
    title : ''
  }
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  bannerId: any;
  careerStatus: string;
  contentId: any;

  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.getBlogList();
  }


  openModal(careerStatus, contentId) {
    this.contentId = contentId;
    this.careerStatus = careerStatus;
    if (careerStatus == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }

  getBlogList() {
    let apiReqUrl: any = `${'static/admin/static-content/get-Blog-data-list'}`
    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data ? res.data : [];
        this.filterArray = res.data ? res.data : [];
        this.totalItems = res.data.length
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
    })
  }
  filterArray : any = []
  search() {
    if(this.calender.title){
      this.filterArray = this.bodyListArray.filter((res)=>{
        let name = String(res.title).toLowerCase()
        return  name.includes(String(this.calender.title).toLowerCase()) 
      })
    }
    if(this.calender.fromdate){
      this.filterArray = this.filterArray.filter((res)=>{
        let createdAt = Date.parse(new Date(res.createdAt).toISOString().split('T')[0])
        return createdAt >= Date.parse(this.calender.fromdate)
      })
    }
    if(this.calender.todate){
      this.filterArray = this.filterArray.filter((res)=>{
        let createdAt = Date.parse(new Date(res.createdAt).toISOString().split('T')[0])
        return createdAt >= Date.parse(this.calender.fromdate) && createdAt <= Date.parse(this.calender.todate)
      })
    }
    this.totalItems = this.filterArray.length
  }
  reset() {
    this.calender.fromdate = ''
    this.calender.todate = ''
    this.calender.type = ''
    this.calender.title = ''

    this.filterArray = this.bodyListArray
    this.totalItems = this.bodyListArray.length
  }
  pagination(event) {

    this.currentPage = event;

  }

  addBlog() {
    this.router.navigate(['/add-blog'])
  }
  viewBlog(item) {
    this.commonService.blogData.next(item)
    this.router.navigate(['/view-blog'])
  }
  editBlog(item) {
    this.commonService.blogData.next(item)
    this.router.navigate(['/edit-blog'])
  }
  validateForm(){

  }

  deleteBlogModal(contentId) {
    $('#deleteBlogId').modal('show')
    this.contentId = contentId
  }
  deleteBlog() {
    let apiReqUrl: any = "static/admin/static-content/delete-Blog-subcategory-by-id?subBlogId=" + this.contentId
    this.commonService.showSpinner();
    this.commonService.post(apiReqUrl, {}).subscribe((res: any) => {
      $('#deleteBlogId').modal('hide');
      if (res.status == 200) {
        this.getBlogList()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }

  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.filterArray = this.filterArray.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.filterArray = this.filterArray.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
