import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from 'src/app/service.service';
import { DatePipe } from '@angular/common';
declare var $: any
@Component({
  selector: 'app-instant-swap-management',
  templateUrl: './instant-swap-management.component.html',
  styleUrls: ['./instant-swap-management.component.css']
})
export class InstantSwapManagementComponent implements OnInit {
  selected: string = "btc";
  coinlist: any = [];
  coinListArr: any = [];
  transferForm: FormGroup;
  loopData: any = [];
  txndata: any = []
  lengthTotal: any;
  minDate: any;
  itemsPerPage = 100;
  currentPage: number = 1;
  totalItems: any
  flip: string = "List";

  today: any = new Date().toISOString().slice(0, 16)

  constructor(
    private router: Router,
    public service: ServiceService,
    private activatedroute: ActivatedRoute,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    this.checkTransferFormValidations();
    this.getCoinWalletList();
    this.getFdList()
    this.getSwapCountDetails()
    this.getAutoManualStatus();
  }

  copyToClipboard(item) {

    this.service.toasterSucc('Copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  isSearched: boolean = false;

  pagination(page) {
    this.currentPage = page;
    if (this.isSearched) {
      this.forSerachValue();
    } else {
      this.getFdList();
    }
  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'coinNameSe': new FormControl(''),
      'status': new FormControl(''),
      'email': new FormControl(''),
      'cName': new FormControl(''),
      'ammount': new FormControl(''),
      'swapType': new FormControl(''),
      'txnType': new FormControl(''),
      'userName': new FormControl(''),
      'address': new FormControl('')


    })
  }
  forSerachValue() {
    let fromDate = Date.parse(this.transferForm.value.fromDate);
    let toDate = Date.parse(this.transferForm.value.toDate);
    let coinNameSe = this.transferForm.value.coinNameSe;
    let status = this.transferForm.value.status
    let address = this.transferForm.value.address
    let email = this.transferForm.value.email
    // let url = `wallet/get-all-swap-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&txnType=SWAP${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.email ? "&email=" + email : ""}${this.transferForm.value.status ? "&status=" + status : ""}`
    let url = `wallet/admin/transaction-history/get-all-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.email ? "&userEmail=" + email : ""}${this.transferForm.value.status ? "&status=" + status : ""}&txnType=SWAP`
  
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.FdListArray = res.data.resultlist || res.data.inProcessFixedDepositDetails || res.data.getEmailHistoryDetailsFromDate || res.data.getFixedHistoryDetails || [];
          this.lengthTotal = res['data'].allCountDetails || res['data'].completeCount || res['data'].inProcessCount || res['data']['totalCount'] ||[];
        }
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  reset() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.coinNameSe ||
      this.transferForm.value.status ||
      this.transferForm.value.email

    ) {
      this.transferForm.reset({
        fromDate: "",
        toDate: "",
        coinNameSe: "",
        status: "",


      });
      this.getFdList();
    }
  }
  getCoinWalletList() {

    this.service.showSpinner();
    this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.coinlist = res.data;
        this.coinlist.forEach(element => {
          this.coinListArr.push(element.coinShortName);
        });
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
      if (error['status'] == 401) {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }
      else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  getExcel() {
    // let url = `wallet/get-all-swap-transaction-history?page=${this.currentPage - 1}&pageSize=${this.lengthTotal}&txnType=SWAP`
    let url = `wallet/admin/transaction-history/get-all-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&txnType=SWAP`
    this.service.get(url).subscribe((res: any) => {
      this.exportAsXLSX(res.data.resultlist)

    }, err => {
      if (err.status >= 400) {
        this.service.toasterErr(err.message)
      }
    })
  }
  exportAsXLSX(FdListArray) {
    let dataArr = [];
    FdListArray.forEach((element, ind) => {
      let temp1:any = ''
      let temp2 = ''
      if(element.txnType =='SWAP'){
temp1 = element.swapDeductAmount
temp2 = element.cancellationFee
      }
      if(element.txnType =='INSTANT_SELL'){
temp1 = element.fromUnit * element.livePrice
temp2 =element.fees
      }
      dataArr.push({
        'S.No': ind + 1,
        'Email': element.userEmail ? element.userEmail : '--',
        'Name': element.userName ? element.userName : '--',
        'Transaction Type': element.txnType ? element.txnType : '--',
        // 'Type': element.deposit ? element.deposit : '--',
        'Trx ID': element.txnId ? element.txnId : '--',
        // 'From': element.coinType ? element.coinType : '--',
        'From Coin': element.coinType ? element.coinType : '--', 
        'Swap Coin': element.swapType== null ? element.fiatName : element.swapType, 
        'From Amount': element.fromUnit ? element.fromUnit : '--',
        'Total Amount': temp1 ? temp1 : '--',
        'Transaction Fee': temp2 ? temp2 : '--',
        'TDS (%)': element.tdsPercentage ? element.tdsPercentage : '--',
        'To Amount': element.coinAmount == null ? element.amount : element.coinAmount || '--',
        // 'TDS (%)': element.txnTime ? this.datePipe.transform(element.txnTime,'medium') : '--',
        'Date & Time': element.txnTime ? this.datePipe.transform(element.txnTime, 'medium') : '--',
        // 'Fee': element.coinAmount ? element.coinAmount : '--',
        // 'Earn Amount': element.finalDeductAmount ? element.finalDeductAmount : '--',
        // 'Status': element.status ? element.status : '--'
      })
    })
    this.service.exportAsExcelFile(dataArr, 'Instant Swap Management');
  }


  FdListArray: any = []

  getFdList() {
    // let url = `wallet/get-all-swap-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&txnType=SWAP`
    let url = `wallet/admin/transaction-history/get-all-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}&txnType=SWAP`
    this.service.showSpinner()
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner()
      this.FdListArray = res.data.resultlist;
      this.lengthTotal = res['data'].totalCount

    }, err => {
      this.service.hideSpinner();
      if (err.status >= 400) {
        this.service.toasterErr(err.message)
      }
    })
  }

  viewFd(fixedDepositId) {
    this.router.navigate(['/fd-view'], { queryParams: { fixedDepositId: fixedDepositId } })
  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.FdListArray = this.FdListArray.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.FdListArray = this.FdListArray.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  validateForm() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.email ||
      this.transferForm.value.status ||
      this.transferForm.value.coinNameSe
    ) {
      return false
    }
    else {
      return true
    }

  }

  swapCount: any;
  
  getSwapCountDetails() {
    this.service.get('/wallet/wallet/swap-count-details').subscribe(res => {
      if (res['status'] == 200) {
        this.swapCount = res['data'];
      }
    }, err => {

      this.service.hideSpinner();

    })
  }
  swapStatus: any
  swapStatus1: any
  autoManual(status) {
    this.swapStatus = status;
    this.swapStatus1 = status.toUpperCase();
    $('#autoManual').modal('show');
  }

  iWantAutoManual() {
    let url = `wallet/admin/auto-manual-swap?adminSwapSettlement=${this.swapStatus1}`
    this.service.showSpinner()
    this.service.post(url, {}).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.getAutoManualStatus();
        $('#autoManual').modal('hide');
        this.service.hideSpinner()
      }
    }, err => {
      this.service.hideSpinner();
      this.service.toasterErr(err.message)
    })
  }
  swapMode: any
  getAutoManualStatus() {
    this.service.get('wallet/admin/get-auto-manual-swap').subscribe(res => {
      if (res['status'] == 200) {
        this.swapMode = res['data'][0]['adminSwapSettlement'];
      }
    }, err => {

      this.service.hideSpinner();

    })
  }

}
