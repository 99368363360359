import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
declare var $: any
@Component({
  selector: 'app-wallet-management',
  templateUrl: './wallet-management.component.html',
  styleUrls: ['./wallet-management.component.css']
})
export class WalletManagementComponent implements OnInit {
  selected: string = "btc";
  coinlist: any = [];
  coinListArr: any = [];
  transferForm: FormGroup;
  loopData: any = [];
  txndata: any = []
  lengthTotal: any;
  minDate: any;
  itemsPerPage = 100;
  currentPage: number = 1;
  totalItems: any
  flip: string = "List";

  today: any = new Date().toISOString().slice(0, 16)

  constructor(
    private router: Router,
    public service: ServiceService
  ) { }

  ngOnInit() {
    this.checkTransferFormValidations();
    this.getCoinWalletList();
    this.getDepositWithdrawDetails()
  }

  copyToClipboard(item) {

    this.service.toasterSucc('Copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  pagination(page) {

    this.currentPage = page
    if (this.flip == "searchCoin") {
      this.searchcoin();
    } else if (this.flip == "searchCoinn") {
      this.searchcoinn();
    }
    else {
      this.getAllTransactionHistory()
    }

  }
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'currency': new FormControl(''),
      'type': new FormControl(''),
      'searchTextTxnHash': new FormControl(''),
      'amount': new FormControl(''),
      'coinNameSe': new FormControl(''),
      'status': new FormControl('')
    })
  }
  forSerachValue() {
    let fromDate = Date.parse(this.transferForm.value.fromDate);
    let toDate = Date.parse(this.transferForm.value.toDate);
    let currency = this.transferForm.value.currency;
    let type = this.transferForm.value.type;
    let searchTextTxnHash = this.transferForm.value.searchTextTxnHash;
    let amount = this.transferForm.value.amount;
    let coinNameSe = this.transferForm.value.coinNameSe;
    let status = this.transferForm.value.status
    let url = `wallet/admin/transaction-history/get-all-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.coinNameSe ? "&coinName=" + coinNameSe : ""}${this.transferForm.value.amount ? "&amount=" + amount : ""}${this.transferForm.value.type ? "&txnType=" + type : ""}${this.transferForm.value.searchTextTxnHash ? "&txnHash=" + searchTextTxnHash : ""}${this.transferForm.value.status ? "&status=" + status : ""}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.loopData = res.data.resultlist;
        }
        this.lengthTotal = res['data'].totalCount
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  reset() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.type ||
      this.transferForm.value.searchTextTxnHash ||
      this.transferForm.value.amount ||
      this.transferForm.value.coinNameSe ||
      this.transferForm.value.status

    ) {
      this.transferForm.reset({
        fromDate: "",
        toDate: "",
        type: "",
        searchTextTxnHash: "",
        amount: "",
        coinNameSe: "",
        status: '',


      });
      this.getAllTransactionHistory();
    }
  }
  getCoinWalletList() {
    this.service.showSpinner();
    this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.getAllTransactionHistory();
        this.coinlist = res.data;
        this.coinlist.forEach(element => {
          this.coinListArr.push(element.coinShortName);
        });
      }
      else {
        this.service.hideSpinner();
        this.getAllTransactionHistory();
      }
    }, (error) => {
      this.service.hideSpinner();
      if (error['status'] == 401) {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }
      else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  selectTab(path) {

    this.selected = path

    if (path == 'btc') {

    } else if (path == 'eth') {

    } else if (path == 'xrp') {

    } else if (path == 'ltc') {

    }
  }

  getBTC() {

  }

  getAllTransactionHistory() {
    this.service.showSpinner()
    this.service.get(`wallet/admin/transaction-history/get-all-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}`).subscribe((res: any) => {
      this.service.hideSpinner()
      this.loopData = res.data.resultlist;
      this.lengthTotal = res['data'].totalCount

    }, err => {
      this.service.hideSpinner();
      if (err.status >= 400) {
        this.service.toasterErr(err.message)
      }
    })

  }

  searchcoinn() {
    this.service.showSpinner()
    this.flip = "searchCoinn"
    this.service.get('wallet/admin/transaction-history/get-all-transaction-history?coinName=' + (this.transferForm.value.currency) + '&page=' + (this.currentPage - 1) + '&pageSize=' + (this.itemsPerPage) + '&txnType=' + (this.transferForm.value.type)).subscribe((res: any) => {
      this.service.hideSpinner()
      this.loopData = res.data.resultlist;
      this.lengthTotal = res.data.totalCount;
    })
  }

  searchcoin() {
    this.service.showSpinner()
    this.flip = "searchCoin"

    this.service.get('wallet/admin/transaction-history/get-all-transaction-history?coinName=' + (this.transferForm.value.currency) + '&page=' + (this.currentPage - 1) + '&pageSize=' + (this.itemsPerPage)).subscribe((res: any) => {
      this.service.hideSpinner()
      this.loopData = res.data.resultlist;
      this.lengthTotal = res.data.totalCount;
    })
  }

  searchText() {
    this.service.showSpinner()
    this.loopData = []
    this.lengthTotal = 0
    this.minDate = this.transferForm.value.fromDate;
    this.service.get('wallet/admin/transaction-history/get-all-transaction-history?fromDate=' + (Math.round(new Date(this.transferForm.value.fromDate).getTime())) + '&toDate=' + (Math.round(new Date(this.transferForm.value.toDate).getTime())) + '&page=' + (this.currentPage - 1) + '&pageSize=' + (this.itemsPerPage)).subscribe((res: any) => {
      this.service.hideSpinner()
      this.loopData = res.data.resultlist;
      this.lengthTotal = res.data.totalCount;
    })
  }

  searchTxnHash() {
    this.service.showSpinner();
    this.txndata = []
    this.lengthTotal = 0
    this.service.get('wallet/admin/transaction-history/get-all-transaction-history?txnHash=' + (this.transferForm.value.searchTextTxnHash) + '&page=' + (this.currentPage - 1) + '&pageSize=' + (this.itemsPerPage)).subscribe((res: any) => {
      this.service.hideSpinner()
      this.txndata = res.data.resultlist;
      this.lengthTotal = res.data.totalCount;
    })
  }

  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.loopData = this.loopData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.loopData = this.loopData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  validateForm() {
    if (
      this.transferForm.value.status ||
      this.transferForm.value.currency ||
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.type ||
      this.transferForm.value.amount ||
      this.transferForm.value.searchTextTxnHash ||
      this.transferForm.value.coinNameSe
    ) {

      return false
    }
    else {
      return true
    }

  }

  depositWithdrawDetails: any;
  getDepositWithdrawDetails() {
    this.service.showSpinner();
    this.service.get('/wallet/admin/dashboard/get-deposit-and-coin-count').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.depositWithdrawDetails = res['data'];
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.toasterErr('Unauthorized');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }


  getTrxHashID(hash, id) {
    if (hash == null || hash == '') {
      return id
    } else {
      return hash
    }
  }

}
