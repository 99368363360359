import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';


declare var $: any
@Component({
  selector: 'app-list-aip-plan',
  templateUrl: './list-aip-plan.component.html',
  styleUrls: ['./list-aip-plan.component.css']
})
export class ListAipPlanComponent implements OnInit {
  aipList: any = []
  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 100;
  currentPage = 1;
  totalItems: any;
  bannerId: any;
  careerStatus: string;
  contentId: any;
  coinValue:any
  minimumAmount:any
  maximumAmount:any
  constructor(public commonService: ServiceService, private router: Router) { }

  ngOnInit() {
    this.aipPlan();
    this.getAIPCountDetails();
    this.getCurrencyFee();
  }

  aipPlan() {
    let url = `wallet/view-auto-invest?page=${this.currentPage - 1}&pageSize=${this.itemPerPage}`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.aipList = res.data;
          this.totalItems = res.data.totalElements
          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
        }
      },
      (error) => {
        this.commonService.hideSpinner();
      }
    );
  }
  enabelDesableaipPlan() {
    let url = `wallet/view-auto-invest?page=${this.currentPage - 1}&pageSize=${this.itemPerPage}`
    this.commonService.showSpinner();
    this.commonService.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.aipList = res.data;
          this.totalItems = res.data.totalElements
          this.commonService.hideSpinner();
        } else {
          this.commonService.hideSpinner();
        }
      },
      (error) => {
        this.commonService.hideSpinner();
      }
    );
  }

  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });

    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  pagination(event) {
    this.currentPage = event;
    this.aipPlan()
  }

  addBody(planId) {
    this.router.navigate(['/add-aip-percent'], { queryParams: { planId: planId } })
  }
  viewBody(contentId) {
    this.router.navigate(['/view-aip-details'], { queryParams: { planId: contentId } })
  }
  editBody(contentId) {
    this.router.navigate(['/edit-blog'], { queryParams: { planId: contentId } })
  }
  deleteCategoryModal(contentId) {
    $('#deleteCategory').modal('show')
    this.contentId = contentId
  }
  deleteCategory() {
    let apiReqUrl: any = "wallet/delete-auto-plan-by-planId?planId=" + this.contentId
    this.commonService.showSpinner();
    this.commonService.delete(apiReqUrl).subscribe((res: any) => {
      $('#deleteCategory').modal('hide');
      if (res.status == 200) {
        this.aipPlan()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }

  aipCount: any;
  getAIPCountDetails() {
    this.commonService.get('/wallet/wallet/aip-count-details').subscribe(res => {
      if (res['status'] == 200) {
        this.aipCount = res['data'];
      }
    }, err => {
      this.commonService.hideSpinner();
    })
  }
  planID: any
  action: any
  planopenId: any
  openDeeleAipModal(plan) {
    this.planopenId = plan
    $('#deleteAip').modal('show')
  }
  openModal(action, plan) {
    this.planID = plan;
    this.action = action;
    if (action == 'DISABLE') {
      $('#blockFd').modal('show')

    } else if (action == 'ENABLE') {
      $('#activeFd').modal('show')
    }
  }
  deleteAip() {
    var url = `wallet/delete-auto-plan-by-planId?planId=${this.planopenId}`;
    this.commonService.showSpinner();
    this.commonService.delete(url).subscribe(res => {
      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        $('#deleteAip').modal('hide')
        this.aipPlan();
        this.commonService.toasterSucc(res['message'])
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  performAction() {
    var url = `wallet/enable-disable-admin-api?planId=${this.planID}&statusEnDis=${this.action}`;
    this.commonService.showSpinner();
    this.commonService.post(url, '').subscribe(res => {
      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == 'DISABLE') {
          $('#blockFd').modal('hide');
          this.commonService.toasterSucc('Coin Disabled Successfully');
        }
        else {
          $('#activeFd').modal('hide');
          this.commonService.toasterSucc('Coin Enabled Successfully');
        }
        this.aipPlan();
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  inrValue: any
  usdtValue: any
  setCurrencyModal() {
    $('#modalLoginForm').modal('show')
  }
  setMinMAxAmount() {
    $('#minMaxModal').modal('show')
  }
  withdrawlFee:any
  tradingFee(){
    this.commonService.get(`wallet/coin/get-coin-details?coinName=${this.coinValue}`).subscribe((res:any)=>{
      this.withdrawlFee=res.data['coinData'];
      this.maximumAmount = this.withdrawlFee.aipMaxLimit
      this.minimumAmount = this.withdrawlFee.aipMinLimit
    })
  }
  setMinMaxAmount(){
    var url = `wallet/coin/admin/aip-min-max-limit?fiat=${this.coinValue}&maxLimit=${this.maximumAmount}&minLimit=${this.minimumAmount}`;
    const body = {
    }
    this.commonService.showSpinner();
    this.commonService.put(url, body).subscribe(res => {
      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        $('#minMaxModal').modal('hide')
        this.tradingFee()
      }
    }, err => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr('Unauthorized Access');
    })
  }
  setCurrencyFee() {
    var url = `wallet/admin/aip-currency-fee`;
    const body = {
      "aipInrFee": this.inrValue,
      "aipUsdtFee": this.usdtValue
    }
    this.commonService.showSpinner();
    this.commonService.post(url, body).subscribe(res => {
      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        $('#modalLoginForm').modal('hide')
        this.getCurrencyFee();
      }
    }, err => {
      this.commonService.hideSpinner();
      this.commonService.toasterErr('Unauthorized Access');
    })
  }

  getCurrencyFee() {
    this.commonService.get('wallet/admin/get-aip-currency-fee').subscribe((res: any) => {
      if (res['status'] == 200) {
        this.inrValue = res.data[0].aipInrFee
        this.usdtValue = res.data[0].aipUsdtFee
      }
    }, err => {
      this.commonService.hideSpinner();
    })
  }


}
