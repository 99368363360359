import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $
@Component({
  selector: 'app-list-exec-base-coin',
  templateUrl: './list-exec-base-coin.component.html',
  styleUrls: ['./list-exec-base-coin.component.css']
})
export class ListExecBaseCoinComponent implements OnInit {
  selected: string = "btc";
  coinlist: any = [];
  coinListArr: any = [];
  transferForm: FormGroup;
  loopData: any = [];
  txndata: any = []
  lengthTotal: any;
  minDate: any;
  itemsPerPage = 100;
  currentPage: number = 1;
  page: number = 1;
  pageSize = 100;
  totalItems: any
  flip: string = "List";

  today: any = new Date().toISOString().slice(0, 16)

  constructor(
    private router: Router,
    public service: ServiceService,
    private activatedroute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.checkTransferFormValidations();
    this.getCoinWalletList();
    this.getFdList()
    this.getSwapCountDetails()
  }

  copyToClipboard(item) {

    this.service.toasterSucc('Copied')
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }
  isSearched: boolean = false;

  pagination(page) {
    this.page = page;
    this.getFdList()
  }

  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'coinNameSe': new FormControl(''),
      'status': new FormControl(''),
      'email': new FormControl(''),
      'cName': new FormControl(''),
      'ammount': new FormControl(''),
      'swapType': new FormControl(''),
      'txnType': new FormControl(''),
      'userName': new FormControl(''),
      'address': new FormControl('')


    })
  }
  forSerachValue() {
    let fromDate = Date.parse(this.transferForm.value.fromDate);
    let toDate = Date.parse(this.transferForm.value.toDate);
    let coinNameSe = this.transferForm.value.coinNameSe;
    let status = this.transferForm.value.status
    let address = this.transferForm.value.address
    let email = this.transferForm.value.email
    let url = `wallet/get-all-swap-transaction-history?page=${this.currentPage - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.email ? "&email=" + email : ""}${this.transferForm.value.status ? "&fixedStatus=" + status : ""}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.FdListArray = res.data.completeFixedDepositDetails || res.data.inProcessFixedDepositDetails || res.data.getEmailHistoryDetailsFromDate || res.data.getFixedHistoryDetails || [];
          this.lengthTotal = res['data'].allCountDetails || res['data'].completeCount || res['data'].inProcessCount || [];
        }
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  reset() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.coinNameSe ||
      this.transferForm.value.status ||
      this.transferForm.value.email

    ) {
      this.transferForm.reset({
        fromDate: "",
        toDate: "",
        coinNameSe: "",
        status: "",


      });
      this.getFdList();
    }
  }
  getCoinWalletList() {
    this.service.showSpinner();
    this.service.get('wallet/coin/get-coin-list').subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        this.coinlist = res.data;
        this.coinlist.forEach(element => {
          this.coinListArr.push(element.coinShortName);
        });
      }
      else {
        this.service.hideSpinner();
      }
    }, (error) => {
      this.service.hideSpinner();
      if (error['status'] == 401) {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }
      else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  exportAsXLSX() {
    let dataArr = [];
    this.FdListArray.forEach((element, ind) => {
      dataArr.push({
        "S no": ind + 1,
        "Customer Name": element.userName ? element.userName : '--',
        "Customer Email": element.email ? element.email : '',

        "Currency Type": element.coinName ? element.coinName : '--',
        "Principle Amt": element.coinAmount ? element.coinAmount : '--',
        "Start Date": element.createTime ? element.createTime.slice(0, 10) : '--',
        "End Date": element.payOutTime ? element.payOutTime.slice(0, 10) : '--',
      })
    })

    this.service.exportAsExcelFile(dataArr, 'Fixed Deposit list');
  }


  FdListArray: any = []

  getFdList() {
    let url = `wallet/admin/get-swapCoinPair?page=${this.page - 1}&pageSize=${this.pageSize}`
    this.service.showSpinner()
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner()
      this.FdListArray = res.data.List;
      this.lengthTotal = res['data'].count

    }, err => {
      this.service.hideSpinner();
      if (err.status >= 400) {
        this.service.toasterErr(err.message)
      }
    })
  }

  viewFd(fixedDepositId) {
    this.router.navigate(['/fd-view'], { queryParams: { fixedDepositId: fixedDepositId } })
  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.FdListArray = this.FdListArray.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.FdListArray = this.FdListArray.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  validateForm() {
    if (
      this.transferForm.value.fromDate ||
      this.transferForm.value.toDate ||
      this.transferForm.value.email ||
      this.transferForm.value.status ||
      this.transferForm.value.coinNameSe
    ) {
      return false
    }
    else {
      return true
    }

  }

  swapCount: any;
  
  getSwapCountDetails() {
    this.service.get('/wallet/wallet/swap-count-details').subscribe(res => {
      if (res['status'] == 200) {
        this.swapCount = res['data'];
      }
    }, err => {

      this.service.hideSpinner();

    })
  }
  action: any
  baseCoin: any
  exceCoin: any
  openModal(action, base, exce) {
    this.baseCoin = base;
    this.exceCoin = exce
    this.action = action;
    if (action == true) {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  coinId: any
  openModalss(coinId) {
    this.coinId = coinId
    $('#deleteModal').modal('show')
  }
  editScreen(id) {
    this.router.navigate(['/edit-swap-coin'], { queryParams: { id } })
  }
  deleteFunction() {
    var url = `wallet/admin/delete-SwapCoinPair?coinId=${this.coinId}`
    this.service.showSpinner();
    this.service.delete(url).subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        $('#deleteModal').modal('hide')
        this.service.toasterSucc('Pair Deleted Successfully');
        this.getFdList();
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  performAction() {
    let url = `wallet/admin/enable-Disable-SwapCoinPair?baseCoin=${this.baseCoin}&enableDisable=${this.action}&exCoin=${this.exceCoin}`
    this.service.showSpinner();
    this.service.post(url, '').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == true) {
          $('#block').modal('hide');
          this.service.toasterSucc('Pair Activated Successfully');
        }
        else {
          $('#active').modal('hide');
          this.service.toasterSucc('Pair Blocked Successfully');
        }
        this.getFdList();
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  updateMinAmountSwap(base, exe, swapFee) {
    let url = `wallet/add-update-swapfee?baseCoin=${base}&exCoin=${exe}&swapFee=${swapFee}`
    this.service.post(url, '').subscribe(res => {
      this.service.hideSpinner();
      this.getFdList()
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })

  }
}
