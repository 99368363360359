import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-announcement-category',
  templateUrl: './edit-announcement-category.component.html',
  styleUrls: ['./edit-announcement-category.component.css']
})
export class EditAnnouncementCategoryComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  announcementId: any;
  editData: any;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService, public active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.announcementId = res.announcementId;
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.viewBlog()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'title': new FormControl('', [Validators.required]),
      'description': new FormControl(''),
      'image': new FormControl('')
    })
  }

  addSwatches() {
    let url = `static/update-announcement-new-category?announcementId=${this.announcementId}`
    let data = {
      'title': this.addSwatchesForm.value.title,
      'discription': this.addSwatchesForm.value.description,
      'img': this.imageUrl,
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/list-announcement-category'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  viewBlog() {
    this.commonService.showSpinner()
    this.commonService.get("static/view-announcement-new-category-by-id?announcementId=" + this.announcementId).subscribe((res: any) => {
      if (res.status == 200) {
        this.editData = res.data;
        this.commonService.hideSpinner()
        this.addSwatchesForm.patchValue({
          'title': this.editData.title,
          'description': this.editData.discription
        })
        this.imageUrl = this.editData.img
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })

  }

  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }



}
