import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-career-subcategory-edit',
  templateUrl: './career-subcategory-edit.component.html',
  styleUrls: ['./career-subcategory-edit.component.css']
})
export class CareerSubcategoryEditComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  bodyListArray:any  = [];
  subTeamId:any
  constructor(private router: Router, public commonService: ServiceService, public active:ActivatedRoute) { 
    this.active.queryParams.subscribe((res:any)=>{
      this.subTeamId=res.subTeamId;
 
      
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getEmbellishmentList();
    this.viewBlog();
  
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'subcatName': new FormControl('', [Validators.required]),
      'jobType': new FormControl('', [Validators.required]),
      'category': new FormControl('', [Validators.required, Validators.pattern(/^[A-Za-z][A-Za-z ]*$/)]),
      'description': new FormControl('', [Validators.required]),
      'requirements': new FormControl('',[Validators.required]),
    })
  }
  addSwatches() {
    let url = `static/sub-category-details-update`
    let data = {
      'category': this.addSwatchesForm.value.category,
      'subTeamName': this.addSwatchesForm.value.subcatName,
      'careerType': this.addSwatchesForm.value.jobType,
      'requirement': this.addSwatchesForm.value.requirements,
      'description': this.addSwatchesForm.value.description,
      'subTeamId' : this.subTeamId
    }
    this.commonService.showSpinner();
    this.commonService.post(url,data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/career-subcat-list'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
   uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  getEmbellishmentList() {
    let apiReqUrl: any = `static/get-all-career`
    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data ? res.data : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    },(err)=>{
      if(err.status == 404){
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }
      
    })
  }
  editData:any  = []
  viewBlog(){
    this.commonService.showSpinner()
  this.commonService.get("static/get-details-by-subCategoryId?subTeamId="+this.subTeamId).subscribe((res:any)=>{
    if (res.status=200) {
      this.editData=res.data[0];
      this.commonService.hideSpinner()
      this.addSwatchesForm.patchValue({
        'category':this.editData.category,
        'subcatName':this.editData.subTeamName,
        'jobType':this.editData.careerType,
        'requirements':this.editData.requirement,
         'description':this.editData.description,
      })
  
    }
   },err=>{
     this.commonService.hideSpinner();
     if(err['status']=='401'){
       this.commonService.toasterErr('Unauthorized Access');
     }else{
     this.commonService.toasterErr('Please wait for few minutes & reload the site.');
  }
   })
  
  } 
}
