import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from '../service.service';
declare var $: any;
@Component({
  selector: 'app-trading-history',
  templateUrl: './trading-history.component.html',
  styleUrls: ['./trading-history.component.css']
})
export class TradingHistoryComponent implements OnInit {
  liqudityaStatusGet: any
  pageNumber: number = 1;
  status: string;
  kycList: any = [];
  kycData: any = {};
  userKycId: any;
  kycDetail: any;
  itemsPerPage: any = 100;
  transferForm: FormGroup;
  today: any = new Date().toISOString().split('T')[0]
  constructor(public service: ServiceService, public router: Router, public activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(res => {
    })
  }

  ngOnInit() {
    this.checkTransferFormValidations()
    this.getAllCoinPairFunc();
    this.getLiqudityV()
  }
  // form validatioons 
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'status': new FormControl(''),
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'pair': new FormControl(''),
      'side': new FormControl(''),
    })
  }

  reset() {
    this.transferForm.reset({
      fromDate: "",
      toDate: "",
      status: "",
      pair: "",
      side: "",
    });
    if(this.liqudityaStatusGet == 'DISABLE') {
      this.getTradeHistory()
    }else{

      this.liquidity();
    }
  }
  changePageNumber(page) {
    this.pageNumber = page;
  }
  disableSearchbtn() {
    if (this.transferForm.value.status || this.transferForm.value.fromDate || this.transferForm.value.toDate || this.transferForm.value.pair || this.transferForm.value.side) {
      return false
    }
    else {
      return true
    }
  }

  baseCurrency: any = []
  baseCurrency111: any = []
  fullCoin: any = []
  exeCurrency: any = []
  arr: any = [];
  execCoin: any = "BTC";
  baseCoin: any = "USD";
  coinDetail: any = {};
  basecoins = "BTC";

  getAllCoinPairFunc() {
    this.arr = [];
    this.service.get("wallet/coin/get-symbol-list").subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.baseCurrency = res['data'];
          this.baseCoin = this.coinDetail.base;
          this.execCoin = this.coinDetail.exec;
          var ind = res.data.findIndex((x) => x.baseCoin == this.basecoins);
          if (ind > -1) {
            res.data[ind].execCoin.forEach((element) => {
              this.arr.push({
                base: this.basecoins,
                executable: element,
                lastPrice: "",
                hourChange: "",
                hourhigh: "",
                hourLow: "",
                hourVol: "",
              });
            });
          }
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }

  liquidityData: any = []
  currentPage: any = 0
  limit: any = 10
  limits: any = 1000
  liquidityCount: any
  liquidity() {
    let fromDate = Date.parse(this.transferForm.value.fromDate)
    let toDate = Date.parse(this.transferForm.value.toDate)
    this.service.showSpinner();
    let url = `order/admin/liquidity-list?instrument=${this.transferForm.value.pair ? this.transferForm.value.pair : 'BTC_USDT'}&page=${this.currentPage}&pageSize=${this.limit}${this.transferForm.value.status ? "&orderStatus=" + this.transferForm.value.status : ''}${fromDate ? "&fromDate=" + fromDate : ""}${toDate ? "&toDate=" + toDate : ""}`
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.liquidityData = res.data.List
          this.liquidityCount = res.data.count
        } else {
          this.service.toasterErr(res.message);
          this.liquidityData = []
        }
      },
      (err) => {
        this.liquidityData = []
        this.service.hideSpinner();
        this.service.toasterErr("No Data Found.");
      }
    );
  }
  getLiqudityV() {
    let url = `order/admin/get-liquidity-platform-list`
    this.service.get(url).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.liqudityaStatusGet = res.data[0].liquidityStatus
          if (this.liqudityaStatusGet == 'DISABLE') {
            this.getTradeHistory()
          } else {
            this.liquidity()
          }
        } else {
          this.service.toasterErr(res.message);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr("No Data Found.");
      }
    );
  }
  getTradeHistory() {
    console.log(this.transferForm.value.pair.split("_")[0]);
    
    let url = `order/get-trade-history-allUser`
    let data = {
      "baseCoin": this.transferForm.value.pair.split("_")[1] || "USDT",
      "exeCoin": this.transferForm.value.pair.split("_")[0] || "BTC",
      "fromDate": Date.parse(this.transferForm.value.fromDate),
      "side": this.transferForm.value.side,
      "toDate": Date.parse(this.transferForm.value.toDate),
      // "transactionId": 0,
      // "userId": 0
    }
   let data1 = this.service.removeEmptyKey(data)
    this.service.post(url, data1).subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.liquidityData = res.data.history
        } else {
          this.service.toasterErr(res.message);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.toasterErr("No Data Found.");
      }
    );

  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.liquidityData = this.liquidityData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.liquidityData = this.liquidityData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
  sortAgentLiquidity(key) {
    if (this.isAssending) {
      this.liquidityData = this.liquidityData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.liquidityData = this.liquidityData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
