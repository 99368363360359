import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { title } from 'process';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-braodcasting',
  templateUrl: './braodcasting.component.html',
  styleUrls: ['./braodcasting.component.css']
})
export class BraodcastingComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  limitSelection = false;
        selectedItems: any = [];
        userListArray: any = [];
        stateSelect: FormControl = new FormControl();
  closeDropdownSelection=false;
  disabled=false;
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    defaultOpen: false,
    idField: "userId",
    textField: "email",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    enableCheckAll: true,
    itemsShowLimit: 1,
    allowSearchFilter: true
  };
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.getUserList()
  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'title': new FormControl(''),
      'description': new FormControl('', [Validators.required]),
      'userIds': this.stateSelect,
    })
  }
getUserList(){
  let url =`account/admin/user-management/user-list`
  this.commonService.showSpinner();
  this.commonService.get(url).subscribe((res: any) => {
    if (res.status == 200) {
      this.userListArray = res.data ? res.data : '';
      this.totalItems = res.data.count
      this.commonService.hideSpinner();
    } else {
      this.userListArray = []
      this.totalItems = 0
      this.commonService.hideSpinner();
      this.commonService.toasterErr(res.message)
    }
  }, (err) => {
    if (err.status == 404) {
      this.userListArray = []
      this.totalItems = 0
      this.commonService.hideSpinner();
    }
  })
}
valueState:any =[]
newData:any
onItemSelect(item: any) {
  this.valueState.push(item.userId) 
}
onSelectAll(item){
  
  this.valueState = []
  for (const iterator of item) {
    this.valueState.push(iterator.userId)
  }
}
onDeSelect(item){
  let findIndex = this.valueState.findIndex((ele)=>{
    return ele.userId == item.userId
  })

  this.valueState.splice(findIndex, 1)
}


  addSwatches() {
  
    let url = `notification/broadcast-message` 
    let data = {
      'title': 'Announcement',
      'message': this.addSwatchesForm.value.description,
      'userIds' : this.valueState
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        // this.router.navigate(['/list-blog'])
        this.commonService.hideSpinner();
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    },(err=>{
      this.commonService.hideSpinner();
    }))
  }

}
