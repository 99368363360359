import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-listing-user-view',
  templateUrl: './listing-user-view.component.html',
  styleUrls: ['./listing-user-view.component.css']
})
export class ListingUserViewComponent implements OnInit {
  listingId: any;
  skirtListArray: any;
  colorList : any = [];
  editData:any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.listingId = res.listingId;
    })
    this.viewBlog()
  }

  
 viewBlog(){
  this.commonService.showSpinner()
this.commonService.get("static/Get-Listing-List-By-Listing-Id?listingId="+this.listingId).subscribe((res:any)=>{
  if (res.status=200) {
    this.editData=res.data[0];
    this.commonService.hideSpinner()

  }
 },err=>{

   this.commonService.hideSpinner();
   if(err['status']=='401'){
     this.commonService.toasterErr('Unauthorized Access');
   }else{
   this.commonService.toasterErr('Please wait for few minutes & reload the site.');
}
 })

} 

}
