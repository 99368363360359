import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../service.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-term-and-service',
  templateUrl: './term-and-service.component.html',
  styleUrls: ['./term-and-service.component.css']
})
export class TermAndServiceComponent implements OnInit {
  terms:any=[];
  id: any;
  dataa: any=[];
  constructor(public service:ServiceService, public route: ActivatedRoute,private router:Router) { }

  ngOnInit() {
    this.route.params.subscribe(x=>{

      this.id= x['id'];
    })
    this.getListCode();
  }


  // Get List Code
  getListCode(){
    this.service.showSpinner();
    this.service.get('static/get-static-page-data?pageKey=TERMS AND CONDITIONS').subscribe(res=>{

      this.service.hideSpinner();
      if(res['status']== 200){
        setTimeout(() => {
          this.dataa = res['data'];
        }, 500);
      }
    }, err=>{

      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr('Please wait for few minutes & reload the site.');
   }
    })
  }

 saveTerms(){
  var apiReq = {
    "pageKey": "Terms And Conditions",
   "pageData": this.dataa.pageData
 }
 this.service.showSpinner();
 this.service.post('static/update-static-content-data',apiReq).subscribe(res=>{

   this.service.hideSpinner();
   if(res['status']== 200){
     this.getListCode();
     this.router.navigate[('/statics-content')]

    this.service.toasterSucc('Terms & Condition Updated Successfully')
   }else{
     this.service.toasterErr('Terms & Condition Not Updated ')
   }
 }, err=>{

   this.service.hideSpinner();
   if(err['status']=='401'){
     this.service.onLogout();
     this.service.toasterErr('Unauthorized Access');
   }else{
   this.service.toasterErr('Please wait for few minutes & reload the site.');
}
 })
 }

}
