import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from '../service.service';

@Component({
  selector: 'app-settlement-management',
  templateUrl: './settlement-management.component.html',
  styleUrls: ['./settlement-management.component.css']
})
export class SettlementManagementComponent implements OnInit {
  minAge: Date;
  fromDate: any = ''
  twoDate: any = ''
  calender: any = { todate: '', fromdate: '' }
  searchByEmail: any;
  coinSelected: any = '';

  walletWithdrawList: any = []
  fixedWithdrawList: any = []
  swapWithdrawList: any = []
  itemsPerPage = 100
  currentPage = 1
  totalItems: any

  currTab: any = 'WALLET';

  totalAmount: any = 0;
  totalWithdrawRequest: any = 0;
  tab1Count: any;

  constructor(public service: ServiceService, private datePipe: DatePipe, private router: Router) { }

  ngOnInit() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate())
    this.getCoinList()
    this.getWalletList()
    this.getTds()
  }

  // from/to date validation
  fromdate() {
    this.fromDate = new Date(this.calender.fromdate)
    this.fromDate = this.fromDate.getTime()
  }
  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime()
  }

  // select tab
  selectTab(tab) {
    this.currentPage = 1
    this.totalItems = 0
    this.currTab = tab
    this.searchByEmail = ''
    this.fromDate = ''
    this.twoDate = ''
    this.calender = { todate: '', fromdate: '' }
    switch (this.currTab) {
      case 'WALLET':
        this.getWalletList();
        this.walletWithdrawList = []
        break;
      case 'FIXED':
        this.fixedWithdrawList = []
        this.getFixedList();
        break;
      case 'SWAP':
        this.swapWithdrawList = []
        this.getSwapList();
        break
    }
  }


  settleAll() { }

  // get active logs of customer
  getWalletList() {
    // 'wallet/get-settlement-management-list-of-withdraw?page=0&pageSize=10'
    let apiReqUrl = `${'wallet/wallet/get-settlement-management-list-of-withdraw?page=' + (this.currentPage - 1) + '&pageSize=' + this.itemsPerPage +
      (this.searchByEmail ? ('&email=' + this.searchByEmail) : '') + (this.coinSelected ? ('&assetType=' + this.coinSelected) : '') +
      (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`
    this.service.showSpinner();
    this.service.get(apiReqUrl).subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.walletWithdrawList = res['data'].list.map(ele => ({ ...ele, isSelected: false }))
        // this.colorListArray = res.result.map(ele => ({ ...ele, isSelected: false }))  // add isSelected key to show check-right
        this.tab1Count = res['data'].totalWithdrawRequest;
        this.totalItems = res['data'].totalWithdrawRequest;
        this.totalAmount = res['data'].totalWithdrawAmount;
        this.totalWithdrawRequest = res['data'].totalWithdrawRequest;
        // this.service.toasterSucc(res['message'])
      } else {
        this.walletWithdrawList = []
        this.totalItems = 0
        this.totalAmount = 0
        this.totalWithdrawRequest = 0
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
    })
  }
  checkWithdrawalItem(item) {
    if (item) {
      item.isSelected = false;
    } else {
      item.isSelected = true;
    }
  }
  // get active logs of staff
  getFixedList() {
    let apiReqUrl = `${'wallet/get-fixed-deposite-list?page=' + (this.currentPage - 1) + '&pageSize=' + this.itemsPerPage +
      (this.searchByEmail ? ('&search=' + this.searchByEmail) : '') + (this.coinSelected ? ('&coinName=' + this.coinSelected) : '') +
      (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`

    this.service.showSpinner();
    this.service.get(apiReqUrl).subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.fixedWithdrawList = res['data'].list
        this.totalItems = res['data'].totalWithdrawRequest
        this.tab2Count = res['data'].totalWithdrawRequest;
        this.totalAmount = res['data'].totalWithdrawAmount;
        this.totalWithdrawRequest = res['data'].totalWithdrawRequest;
      } else {
        this.fixedWithdrawList = []
        this.totalItems = 0
        this.totalAmount = 0
        this.totalWithdrawRequest = 0
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
    })
  }

  // get active logs of admin
  getSwapList() {
    let apiReqUrl = `${'wallet/get-swap-awaiting-list?page=' + (this.currentPage - 1) + '&pageSize=' + this.itemsPerPage +
      (this.searchByEmail ? ('&search=' + this.searchByEmail) : '') + (this.coinSelected ? ('&swapType=' + this.coinSelected) : '') +
      (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`
    this.service.showSpinner();
    this.service.get(apiReqUrl).subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.swapWithdrawList = res['data'].list
        this.tab3Count = res['data'].totalWithdrawRequest;
        this.totalItems = res['data'].totalWithdrawRequest
        this.totalAmount = res['data'].totalWithdrawAmount;
        this.totalWithdrawRequest = res['data'].totalWithdrawRequest;
      } else {
        this.swapWithdrawList = []
        this.totalItems = 0
        this.totalAmount = 0
        this.totalWithdrawRequest = 0
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner()
      this.service.toasterErr(err['message'])
    })
  }

  // common search for active logs of customer/staff/agent
  search() {
    if (this.searchByEmail || this.fromDate || this.twoDate || this.coinSelected) {
      switch (this.currTab) {
        case 'WALLET':
          this.getWalletList();
          break;
        case 'FIXED':
          this.getFixedList();
          break;
        case 'SWAP':
          this.getSwapList();
          break
      }
    } else {
    }
  }
  // common reset for active logs of customer/staff/agent
  reset() {
    if (this.searchByEmail || this.fromDate || this.twoDate || this.coinSelected) {
      this.searchByEmail = ''
      this.fromDate = ''
      this.twoDate = ''
      this.calender = { todate: '', fromdate: '' }
      this.coinSelected = ''
      switch (this.currTab) {
        case 'WALLET':
          this.getWalletList();
          break;
        case 'FIXED':
          this.getFixedList();
          break;
        case 'SWAP':
          this.getSwapList();
          break
      }
    }
  }

  // common pagination for active logs of customer/staff/agent
  pagination(page) {
    this.currentPage = page
    switch (this.currTab) {
      case 'WALLET':
        this.getWalletList();
        break;
      case 'FIXED':
        this.getFixedList();
        break;
      case 'SWAP':
        this.getSwapList();
        break
    }
  }

  tab3Count: any
  tab2Count: any

  getExcel() {
    switch (this.currTab) {
      case 'WALLET':
        this.getWallExcel();
        break;
      case 'FIXED':
        this.getFixExcel();
        break;
      case 'SWAP':
        this.getSwapExcel();
        break
    }
  }
  getWallExcel() {
    let apiReqUrl = `${'wallet/wallet/get-settlement-management-list-of-withdraw?page=' + (this.currentPage - 1) + '&pageSize=' + this.tab1Count +
      (this.searchByEmail ? ('&email=' + this.searchByEmail) : '') + (this.coinSelected ? ('&assetType=' + this.coinSelected) : '') +
      (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`
    this.service.get(apiReqUrl).subscribe((res) => {
      if (res['status'] == 200) {
        let item = res['data'].list.map(ele => ({ ...ele, isSelected: false }))
        this.exportAsXLXS(item)
      }
    }, err => {
      this.service.toasterErr(err['message'])
    })
  }
  getFixExcel() {
    let apiReqUrl = `${'wallet/get-fixed-deposite-list?page=' + (this.currentPage - 1) + '&pageSize=' + this.tab2Count +
      (this.searchByEmail ? ('&search=' + this.searchByEmail) : '') + (this.coinSelected ? ('&coinName=' + this.coinSelected) : '') +
      (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`
    this.service.get(apiReqUrl).subscribe((res) => {
      if (res['status'] == 200) {
        let tim = res['data'].list
        this.exportAsXLXS(tim)
      }
    }, err => {
      this.service.toasterErr(err['message'])
    })
  }
  getSwapExcel() {
    let apiReqUrl = `${'wallet/get-swap-awaiting-list?page=' + (this.currentPage - 1) + '&pageSize=' + this.tab3Count +
      (this.searchByEmail ? ('&search=' + this.searchByEmail) : '') + (this.coinSelected ? ('&swapType=' + this.coinSelected) : '') +
      (this.fromDate ? ('&fromDate=' + this.fromDate) : '') + (this.twoDate ? ('&toDate=' + this.twoDate) : '')}`
    this.service.get(apiReqUrl).subscribe((res) => {
      this.service.hideSpinner()
      if (res['status'] == 200) {
        this.exportAsXLXS(res['data'].list)
      }
    }, err => {
      this.service.toasterErr(err['message'])
    })
  }

  exportAsXLXS(itemExcel) {
    let dataArr = [];
    switch (this.currTab) {
      case 'WALLET':
        itemExcel.forEach((element) => {
          dataArr.push({
            'User Id': element.userId ? element.userId : '--',
            'Username': element.userName ? element.userName : '--',
            'Email': element.email ? element.email : '--',
            'Assets Type': element.assetType ? element.assetType : '--',
            'Amount	': element.amount ? element.amount : '--',
            'Requested Date & Time	': element.createTime ? this.datePipe.transform(element.createTime, 'medium') : '--',
            'Withdrawal Status	': element.withdrawalStatus ? element.withdrawalStatus : '--',
            // 'IP Address': element.ipAddress ? element.ipAddress : '--',
            // 'Browser Agent': element.userAgent ? element.userAgent : '--',
            'Wallet Address': element.toAddress ? element.toAddress : '--',
            'Withdraw Id': element.withDrawRequestId ? element.withDrawRequestId : '--'
          })
        })
        break;
      case 'FIXED':
        itemExcel.forEach((element) => {
          dataArr.push({
            'User Id': element.userId ? element.userId : '--',
            'Customer Name': element.userName ? element.userName : '--',
            'Customer Email': element.email ? element.email : '--',
            'Assets': element.coinName ? element.coinName : '--',
            "Duration (Days)	In": element.terms ? element.terms : '--',
            'Invested Amount	': element.coinAmount ? element.coinAmount + '(' + 'USD' + ' ' + element.coinAmount + ')' : '--',
            'Start Date	': element.createTime ? this.datePipe.transform(element.createTime, 'medium') : '--',
            'End Date	': element.payOutTime ? this.datePipe.transform(element.payOutTime, 'medium') : '--',
            'Referal Percentage': element.referalPercentage ? element.referalPercentage : '--',
            'Total Amount': element.payOutAmount ? element.payOutAmount : '--',
            'Internal Coin Amount': element.internalCoinAmount ? element.internalCoinAmount : '--',
            'Interest Rate': element.interestRate ? element.interestRate : '--',
            'FD Status': element.fixedStatus ? element.fixedStatus : '--',
            'Admin FD Status': element.fDStatusAdmin ? element.fDStatusAdmin : '--',
            'Action FD': element.actionFD ? element.actionFD : '--',
            'Auto Renewal': element.autoRenewal ? element.autoRenewal : '--',
            'Auto Renewal Status': element.autoRenewalStatus ? element.autoRenewalStatus : '--',
            'FD Cencellation Fee': element.fdCencellationFee ? element.fdCencellationFee : '--',
            // 'IP Address': element.ipAddress ? element.ipAddress : '--',
            // 'Browser Agent': element.userAgent ? element.userAgent : '--'
          })
        })
        break;
      case 'SWAP':
        itemExcel.forEach((element, index) => {
          dataArr.push({
            'S.No.': index + 1,
            // 'Customer Name': element.userId ? element.userId : '--',
            'Customer Name': element.userName ? element.userName : '--',
            'Customer Email': element.email ? element.email : '--',
            'From': element.coinAmount ? (element.coinAmount + ' ' + element.coinType) : '--',
            'Fees': element.cancellationFee ? element.cancellationFee : '--',
            'TDS %': this.tdsData ? this.tdsData : '--',
            'To': element.swapAmount ? (element.swapAmount + ' ' + element.swapType) : '--',
            'Total Amount': element.finalAmount ? element.finalAmount : '--',
            'Swap Status': element.swapStatus ? element.swapStatus : '--',
            'Date & Time': element.swapTime ? this.datePipe.transform(element.swapTime, 'medium') : '--',
          })
        })
        break;
    }
    this.service.exportAsExcelFile(dataArr, 'Settlement Management ' + this.currTab.charAt(0).toUpperCase() + this.currTab.slice(1))
  }

  viewSettlement(managementShow, id) {
    this.router.navigate(['/view-settlement'], { queryParams: { managementShow: managementShow, id: id } })
  }

  coinList: any = []
  getCoinList() {
    this.service.showSpinner();
    this.service.get('wallet/coin/get-coin-list').subscribe((res) => {
      if (res['status'] == 200) {
        this.coinList = res['data']
        let removeCoin = ['INR']
        this.coinList = this.coinList.filter((ele) => {
          return !removeCoin.includes(ele.coinShortName)
        })

        this.service.hideSpinner();
      }

      else {
        this.service.hideSpinner();
      }
    })
  }


  validateForm() {
    if (
      this.searchByEmail || this.fromDate || this.twoDate || this.coinSelected
    ) {

      return false
    }
    else {
      return true
    }

  }
  tdsData: any
  getTds() {
    let url = `wallet/wallet/get-tds-percentage`
    this.service.get(url).subscribe((res: any) => {
      if (res['status'] == 200) {
        this.tdsData = res['data'][0].tdsPercentage
        this.service.hideSpinner();
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err) => {
      this.service.hideSpinner();
      this.service.toasterErr(err.error.message);
    })
  }

  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.walletWithdrawList = this.walletWithdrawList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.walletWithdrawList = this.walletWithdrawList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
  sortAgentFD(key) {
    if (this.isAssending) {
      this.walletWithdrawList = this.walletWithdrawList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.walletWithdrawList = this.walletWithdrawList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
  sortAgentSwap(key) {
    if (this.isAssending) {
      this.swapWithdrawList = this.swapWithdrawList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.swapWithdrawList = this.swapWithdrawList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

}