import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-news-category-edit',
  templateUrl: './news-category-edit.component.html',
  styleUrls: ['./news-category-edit.component.css']
})
export class NewsCategoryEditComponent implements OnInit {

  addFaqForm:FormGroup;
  pageNumber:number=1
  faqId: any;
  editData: any;
  currTab: any;
  constructor(public route:Router,public service:ServiceService, public active:ActivatedRoute) 
  {
    this.active.queryParams.subscribe((res:any)=>{
      this.faqId=res.id;
      
      
    })
   }

  ngOnInit(): void {
    this.formValidation();
    this.editFaq()
  }

  formValidation(){
    this.addFaqForm= new FormGroup({
      'title':new FormControl('', [Validators.required,Validators.pattern('')]),
      
    })
  }

 
  
  editFaq(){
    
   this.service.get("static/get-news-category-by-id?newsId="+this.faqId).subscribe((res:any)=>{
     if (res.status=200) {
       this.editData=res.data;
       this.addFaqForm.patchValue({
         'title':this.editData.category,
         
       })
     }
    },err=>{
   
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr('Please wait for few minutes & reload the site.');
   }
    })

  } 

  
  editFaqGerman(){
    
    this.service.get("static/get-german-faq-detail?faqId="+this.faqId).subscribe((res:any)=>{
      if (res.status=200) {
        this.editData=res.data;
        this.service.toasterSucc(res.message);
        this.addFaqForm.patchValue({
          'title':this.editData.question,
          'description':this.editData.answer
        })
      }
     },err=>{
    
       this.service.hideSpinner();
       if(err['status']=='401'){
         this.service.onLogout();
         this.service.toasterErr('Unauthorized Access');
       }else{
       this.service.toasterErr('Please wait for few minutes & reload the site.');
    }
     })
 
   } 

   
  editFaqSpanish(){
    this.service.get("static/get-spanish-faq-detail?faqId="+this.faqId).subscribe((res:any)=>{
      if (res.status=200) {
        this.editData=res.data;
        this.service.toasterSucc(res.message);
        this.addFaqForm.patchValue({
          'title':this.editData.question,
          'description':this.editData.answer
        })
      }
     },err=>{
    
       this.service.hideSpinner();
       if(err['status']=='401'){
         this.service.onLogout();
         this.service.toasterErr('Unauthorized Access');
       }else{
       this.service.toasterErr('Please wait for few minutes & reload the site.');
    }
     })
 
   } 

  updateFaq(){
    let request = {
      'category':this.addFaqForm.value.title, 
    }
   this.service.post("static/update-news-category?newsId="+this.faqId,request).subscribe((res:any)=>{
     if (res.status=200) {
       this.service.toasterSucc(res.message)
       this.route.navigate(['/news-category-list'])
     }
    },err=>{
   
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr('Please wait for few minutes & reload the site.');
   }
    })

  }

}
