import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-view-academy',
  templateUrl: './view-academy.component.html',
  styleUrls: ['./view-academy.component.css']
})
export class ViewAcademyComponent implements OnInit {
  academyId: any;
  skirtListArray: any;
  colorList: any = [];
  editData: any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.academyId = res.id;
    })
    this.viewBlog()
  }
  viewBlog() {
    this.commonService.showSpinner()
    this.commonService.get("static/view-academy-by-id?subAcademicId=" + this.academyId).subscribe((res: any) => {
      if (res.status = 200) {
        this.editData = res.data;
        this.commonService.hideSpinner()
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })

  }
}
