import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
declare var $: any;

@Component({
  selector: 'app-swap-percentage',
  templateUrl: './swap-percentage.component.html',
  styleUrls: ['./swap-percentage.component.css']
})
export class SwapPercentageComponent implements OnInit {

  editImage: string = 'https://res.cloudinary.com/dmabxaha1/image/upload/v1561717504/bxl4lgzl5inbe27hocsq.png';
  message: any = ''
  bannerId: any;
  coinList: any = [];
  action: any;

  constructor(public service: ServiceService, public router: Router) { }

  ngOnInit() {
    this.getAllCoins();
  }

  
  getAllCoins() {
    this.service.showSpinner();
    this.service.get('wallet/wallet/get-all-user-balance-and-coinlist').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        var coinList = res['data'].coinList;
        for (var x = 0; x < coinList.length; x++) {
          coinList[x]['walletBalance'] = res['data'].userBalance[x].walletBalance;
          coinList[x]['blockedBalance'] = res['data'].userBalance[x].blockedBalance;
        }
        this.coinList = coinList;

      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        localStorage.clear();
        this.router.navigate(['/login']);
        this.service.toasterErr('Unauthorized');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  getBannerDetails() {
    this.service.showSpinner();
    this.service.get('static-content-service/admin/static-content/get-banner-list').subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {

        this.editImage = res['data'][0].imageUrl;
        this.message = res['data'][0].description;
        this.bannerId = res['data'][0].bannerId;

      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.toasterErr('Unauthorized Access');
        this.service.onLogout();
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  uploadImg($event): void {
    var img = $event.target.files[0];

    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.editImage = res['data'];

      } else {
        this.service.toasterErr(res['message']);
      }
      this.service.hideSpinner();
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  saveBannerFunct() {

    var apireq = {
      "description": this.message,
      "imageUrl": this.editImage,
      "bannerId": this.bannerId ? Number(this.bannerId) : 1
    }
    this.service.showSpinner();
    this.service.post('static-content-service/admin/static-content/update-banner', apireq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.toasterSucc(res['message']);
        this.getBannerDetails();
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  updateCoinWithdrawFee(swapFee, coinName, swapFees, maxAmount) {
    let url = `wallet/admin/fee-management/set-swap-plateform-fee?coinName=${coinName}&plateform=${swapFee}&swapFee=${swapFees}&maxAmount=${maxAmount}`
    this.service.showSpinner();
    this.service.get(url).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.getAllCoins();
        this.service.toasterSucc('Value Updated Succesfully')
      } else {
        this.service.toasterErr(res['message'])
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })


  }

  coinShortName: any
  openModal(action, coinShortName) {
    this.coinShortName = coinShortName;
    this.action = action;
    if (action == 'DISABLE') {
      $('#blockFd').modal('show')

    } else if (action == 'ENABLE') {
      $('#activeFd').modal('show')
    }
  }
  performAction() {
    var url = `wallet/admin/fee-management/enable-disable-swap-status?coinName=${this.coinShortName}&swapStatusChanged=${this.action}`;
    this.service.showSpinner();
    this.service.post(url, '').subscribe(res => {

      this.service.hideSpinner();
      if (res['status'] == 200) {
        if (this.action == 'DISABLE') {
          $('#blockFd').modal('hide');
          this.service.toasterSucc('Coin Disabled Successfully');
        }
        else {
          $('#activeFd').modal('hide');
          this.service.toasterSucc('Coin Enabled Successfully');
        }
        this.getAllCoins();
      }
    }, err => {

      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

}
