import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-edit-academy-category',
  templateUrl: './edit-academy-category.component.html',
  styleUrls: ['./edit-academy-category.component.css']
})
export class EditAcademyCategoryComponent implements OnInit {

  addForm: FormGroup;
  academyId: any;
  academyCategoryDetails: any;

  constructor(public service: ServiceService, public activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((res: any) => {
      this.academyId = res.id
      this.getAcademyCategoryById();
    })
    this.addFormValidation()
  }

  addFormValidation() {
    this.addForm = new FormGroup({
      'categoryName': new FormControl('', [Validators.required])
    })
  }

  getAcademyCategoryById() {
    this.service.showSpinner();
    this.service.get("static/view-academic-Category-by-id?AcademicId=" + this.academyId).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc(res.message)
        this.academyCategoryDetails = res.data;
        this.addForm.patchValue({
          'categoryName': res.data.academiccCategory
        })
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err: any) => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  submitForm() {
    var apiReq = {
      "academiccCategory": this.addForm.value.categoryName,
    }
    this.service.showSpinner();
    this.service.post('static/update-academy-category?AcademicId=' + this.academyId, apiReq).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc('Category updated successfully.')
        this.router.navigate(['/list-academy-category'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err: any) => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
}