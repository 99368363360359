import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-edit-blog',
  templateUrl: './edit-blog.component.html',
  styleUrls: ['./edit-blog.component.css']
})
export class EditBlogComponent implements OnInit {

  addSwatchesForm: FormGroup
  imageUrl
  contentId: any;
  editData: any;
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  blogData: any
  constructor(private router: Router, public commonService: ServiceService, public active: ActivatedRoute) {
    this.commonService.blogData.subscribe((res) => {
      if(!Object.keys(res).length){
        this.router.navigate(['/list-blog'])
      }
      this.blogData = res
    })
  }

  ngOnInit() {
    this.addSwatchesFormValidation();
    this.viewBlog()
  }

  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
  
      'title': new FormControl('', [Validators.required]),
      'description': new FormControl('', Validators.required),
  
      'image': new FormControl('')
    })
  }

  addSwatches() {
    let url = `static/admin/static-content/update-Blog-subcategory-by-id`
    var dom = document.createElement("DIV");
    dom.innerHTML=this.addSwatchesForm.value.description;
    var plain_text=(dom.textContent || dom.innerText);
    plain_text = plain_text.trim()
    plain_text = plain_text.replace(/\n/g,' ')
    plain_text = plain_text.replace(/\t/g,' ')
    plain_text = plain_text.replace(/[^\x20-\x7E]/g,'')
    const data = {
      "contentId": this.blogData.subCategoryBlogId,
      "description": this.addSwatchesForm.value.description,
      "image": this.imageUrl,
      "shortDescription": plain_text,
      "title": this.addSwatchesForm.value.title,
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/list-blog'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }

  viewBlog() {
    this.addSwatchesForm.patchValue({
      'title': this.blogData.title,
      'description': this.blogData.description
    })
    this.imageUrl = this.blogData.image
  }
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }




}
