import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.css']
})
export class CookiePolicyComponent implements OnInit {
  id: any;
  privacy: any;
  dataa: any=[];
  constructor(public service:ServiceService, public route: ActivatedRoute,private router:Router) { }

  ngOnInit() {
    this.route.params.subscribe(x=>{

      this.id= x['id'];
    })
    this.getListCode();
  }

  // Get List Code
  getListCode(){
    this.service.showSpinner();
    this.service.get('static/get-static-page-data?pageKey=CookiePolicy').subscribe(res=>{

      this.service.hideSpinner();
      if(res['status']== 200){
        setTimeout(() => {
          this.dataa = res['data'];
        }, 500);

      }
    }, err=>{

      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr('Please wait for few minutes & reload the site.');
   }
    })
  }

  // Save Abou Us Functionality
  savePrivacy(){
    var apiReq = {
     "pageKey": this.dataa.pageKey,
     "pageData": this.dataa.pageData

   }
   this.service.showSpinner();
   this.service.post('static/update-static-content-data',apiReq).subscribe(res=>{

     this.service.hideSpinner();
     if(res['status']== 200){
       this.getListCode();
     this.router.navigate[('/statics-content')]

      this.service.toasterSucc('Privacy Policy Updated Successfully')
     }else{
       this.service.toasterErr('Privacy Policy Not Updated')
     }
   }, err=>{

     this.service.hideSpinner();
     if(err['status']=='401'){
       this.service.onLogout();
       this.service.toasterErr('Unauthorized Access');
     }else{
     this.service.toasterErr('Please wait for few minutes & reload the site.');
  }
   })
   }
}
