import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.css']
})
export class AddBannerComponent implements OnInit {
  addSwatchesForm: FormGroup
  imageUrl

  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  colorListArray: any = []
  showImageField: any = [];
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.addSwatchesFormValidation();

  }
  addSwatchesFormValidation() {
    this.addSwatchesForm = new FormGroup({
      'description': new FormControl('', [Validators.required]),
      'image': new FormControl(''),
      // 'eventStartDate': new FormControl(''),
      // 'eventEndDate': new FormControl(''),
      'redirectUrl': new FormControl(''),
      'subHeading': new FormControl(''),
    })
  }





  addSwatches() {
    let url = `static/add-banner`
    let data = {
      "bannerStatus": "ACTIVE",
      'description': this.addSwatchesForm.value.description,
      'imageUrl': this.imageUrl,
      'eventStartDate' : new Date().toISOString(),
      'eventEndDate' : new Date().toISOString(),
      'redirectUrl' : this.addSwatchesForm.value.redirectUrl,
      'subHeading' : this.addSwatchesForm.value.subHeading
    }
    this.commonService.showSpinner();
    this.commonService.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/list-banner'])
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.commonService.showSpinner();
    this.commonService.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

}
