import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServiceService } from '../service.service';
import { Router } from '@angular/router';
declare var $
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  resetPasswordForm: FormGroup;
  qrCode: any = "";
  secretKey: any = "";
  googleAuth: any = "";
  twoFAStatus: any;
  smsAuth: any = "";
  emailAuth: any = "";
  ipAddress: string;
  profileData: any;
  profileDatas:any
  constructor(public service: ServiceService, public router: Router) { }

  ngOnInit() {
    this.resetPasswordForm = new FormGroup({
      oldPassword: new FormControl('', Validators.required),
      password: new FormControl('', [Validators.required, Validators.pattern('(?=\\D*\\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}')]),
      confirmPassword: new FormControl('', Validators.required)
    })
    this.getProfile1()
  }

  resetPasswordFunc() {
    var apireq = {
      'newPassword': this.resetPasswordForm.value.confirmPassword,
      'oldPassword': this.resetPasswordForm.value.oldPassword
    }
    this.service.showSpinner();
    this.service.post('account/change-password', apireq).subscribe(res => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.service.toasterSucc('Password updated successfully.');
        this.router.navigate(['/login'])
      } else {
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.toasterErr('Unauthorized Access.');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site..');
      }
    })
  }
  getProfile1() {
    if (localStorage.getItem("credential")) {
      // this.service.showSpinner();
      this.service.get(`account/my-account`).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            this.profileDatas = res["data"]["twoFaType"];
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          // this.service.showError(err['message']);
        }
      );
    }
  }

  openModal() {
    this.googleAuth = "";
    this.smsAuth = "";
    this.emailAuth = "";
    // if () {
    //   this.service.toasterErr(
    //     "Coin amount cannot be less than min. withdrawal amount"
    //   );
    // } else {
      if (this.profileDatas == "GOOGLE") {
        $("#googleAuth").modal({ backdrop: "static" });
      } else if (this.profileDatas == "SMS") {
        this.suggesstionFunc("sms");
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileDatas == "EMAIL") {
        this.suggesstionFunc("email");
        // $('#smsAuth').modal({ backdrop: 'static' });
      } else if (this.profileDatas == "NONE" || this.profileDatas == "SKIP") {
        this.service.toasterErr("Please Enable Two FA First.");
      }
    // }
  }
  
  verifyGoogleAuth(select) {
    // this.googleAuth= '';
    if (select == "Google") {
      var apireq = {};
      var url = "";
      if (this.twoFAStatus == "GOOGLE") {
        url = "account/verify-google-code";
        apireq = {
          code: this.googleAuth,
          secretKey: this.secretKey,
        };
      } //account/verify-google-code
      else {
        url = "auth/verify-google";
        apireq = {
          otp: Number(this.googleAuth),
          //"secretKey": this.secretKey
        };
      }
      // this.service.showSpinner();
      this.service.post(url, apireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "GOOGLE") {
              //this.service.changeLoginSub('login');
              localStorage.setItem("credential", res["data"]);
              sessionStorage.setItem("secretKey", this.secretKey);
            }
            // this.service.toasterSucc(res["message"]);
            $("#googleAuth").modal("hide");
            this.googleAuth = "";
            this.resetPasswordFunc();
            // this.routes.navigate(['/landing']);
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.toasterErr("Something Went Wrong");
        }
      );
    } else if (select == "sms") {
      var smsapireq = {};
      var url = "";
      if (this.twoFAStatus == "SMS") {
        url = "auth/verify-sms";
        smsapireq = {
          otp: this.smsAuth,
        };
      } else {
        url = "account/verify-sms-code";
        smsapireq = {
          otp: this.smsAuth,
        };
      }
      // this.service.showSpinner();
      this.service.post(url, smsapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#smsAuth").modal("hide");
  
            //this.service.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "SMS")
              localStorage.setItem("credential", res["data"]);
            // this.service.toasterSucc(res["message"]);
            this.resetPasswordFunc();
            //this.routes.navigate(['/landing']);
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.toasterErr("Something Went Wrong");
        }
      );
    }
  
    // email auth verification
    else if (select == "email") {
      var emailapireq = {};
      var url = "";
      if (this.twoFAStatus == "EMAIL") {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      } else {
        url = "account/verify-Email-Sms-code";
        emailapireq = {
          emailOtp: this.emailAuth,
          ipAddress: this.ipAddress,
          source: "WEB",
        };
      }
      // this.service.showSpinner();
      this.service.post(url, emailapireq).subscribe(
        (res) => {
          this.service.hideSpinner();
          if (res["status"] == 200) {
            $("#emailAuth").modal("hide");
  
            //this.service.changeLoginSub('login');
            localStorage.removeItem("authToken");
            if (this.twoFAStatus == "EMAIL")
              localStorage.setItem("credential", res["data"]);
            // this.service.toasterSucc(res["message"]);
            this.resetPasswordFunc();
            //this.routes.navigate(['/landing']);
          } else {
            this.service.toasterErr(res["message"]);
          }
        },
        (err) => {
          this.service.hideSpinner();
          this.service.toasterErr("Something Went Wrong");
        }
      );
    }
  }
  // to remove token
  removeToken() {
  localStorage.removeItem("authToken");
  }
  
  // 2FA Suggestion Modal Functionality
  suggesstionFunc(action) {
  this.googleAuth = "";
  if (action == "Skip") {
    // this.service.showSpinner();
    this.service.get("account/skip-twoFa").subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.service.toasterSucc("Logged In");
          $("#suggest").modal("hide");
          //this.service.changeLoginSub('login');
          let credential = localStorage.getItem("authToken");
          localStorage.removeItem("authToken");
          localStorage.setItem("credential", credential);
  
          this.router.navigate([""]);
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  } else if (action == "Google") {
    // this.service.showSpinner();
    this.service.get("account/google-auth").subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          // this.qrCode = res['data']['qrCode'];
          this.secretKey = res["data"]["secretKey"];
          $("#suggest").modal("hide");
          $("#googleAuth").modal("show");
        }
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  } else if (action == "sms") {
    // this.service.showSpinner();
    this.service.get("account/send-sms-code").subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $("#suggest").modal("hide");
          $("#smsAuth").modal("show");
  
          // this.service.toasterSucc(res["message"]);
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "500") {
          this.service.toasterErr("Mobile Number Not Verified");
        } else {
          this.service.toasterErr(err["message"]);
        }
      }
    );
  } else if (action == "email") {
    // this.service.showSpinner();
    this.service.get("account/resend-verify-otp").subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          $("#suggest").modal("hide");
          $("#emailAuth").modal("show");
  
          // this.service.toasterSucc(res["message"]);
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "500") {
          this.service.toasterErr("Email Address Not Verified");
        } else {
          this.service.toasterErr(err["message"]);
        }
      }
    );
  }
  }
}
