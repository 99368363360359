import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $
@Component({
  selector: 'app-view-bank-detail-user',
  templateUrl: './view-bank-detail-user.component.html',
  styleUrls: ['./view-bank-detail-user.component.css']
})
export class ViewBankDetailUserComponent implements OnInit {

  userDetail: any;
  profile = ''
  itemsPerPage: any = 100
  userId: any = [];
  status: String
  image: any
  data: any
  constructor(private router: Router, public service: ServiceService, private route: ActivatedRoute) {
  }

  ngOnInit() {

    let obj = this.route.queryParams.subscribe(params => {
      this.userId = (params['userId']);
      this.status = params['status']
      this.image = params['image']
      this.data = JSON.parse(params['dataValue'])
      console.log(this.data);

      localStorage.setItem('userId', this.userId)
    });
    this.viewDetail()

  }



  viewDetail() {
    var url = 'account/admin/user-management/user-details?userId=' + this.userId;
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res['status'] == 200) {
        this.userDetail = res['data']
        this.profile = this.userDetail.imageUrl
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res['message']);
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  back() {
    this.router.navigate(['/bank-management'])
  }
  openImgModal() {
    $('#img1').modal('show')
  }
  bankDetailId: any
  action: any
  openModal(action, userId) {
    this.bankDetailId = userId;
    this.action = action;
    if (action == 'DELETE') {
      $('#deleteModal').modal('show')

    } else if (action == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  performAction() {
    var url = 'account/admin/approve-or-reject-bank-account?bankDetailId=' + (this.bankDetailId) + '&bankStatus=' + ('REJECTED');
    this.service.post(url, '').subscribe((res) => {
      if (res['status'] == 200) {
        $('#block').modal('hide')
        this.service.toasterSucc('Rejected')
        this.data.bankStatus = 'REJECTED'
        this.viewDetail();
      }
    })
  }

  performActionActive() {
    var url = 'account/admin/approve-or-reject-bank-account?bankDetailId=' + (this.bankDetailId) + '&bankStatus=' + ('APPROVED');
    this.service.post(url, '').subscribe((res) => {
      if (res['status'] == 200) {
        $('#active').modal('hide')
        this.service.toasterSucc('Approved')
        this.data.bankStatus = 'APPROVED'
        this.viewDetail();
      }
    })
  }

  upiId: any
  actionSt: any
  openModals(action, upiid) {
    this.actionSt = action
    this.upiId = upiid;
    this.action = action;
    if (action == 'REJECTED') {
      $('#reject').modal('show')

    }
    else {
      $('#approve').modal('show')

    }
  }
  ac: any
  openModalss(a, id) {
    this.ac = a
    this.bankDetailId = id
    if (this.ac == 'REJECTED') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }
  dataapproved: any
  approveUpi() {
    let url = `wallet/admin/upi-status-update?upiStatus=APPROVED&userUpiId=${this.upiId}`
    this.service.put(url, {}).subscribe((res) => {
      if (res['status'] == 200) {
        $('#approve').modal('hide')
        this.data.upiStatus = 'APPROVED'
        this.service.toasterSucc('Approved')
        this.viewDetail()
      }
    })
  }
  datareject: any
  rejectUpi() {
    let url = `wallet/admin/upi-status-update?upiStatus=REJECTED&userUpiId=${this.upiId}`
    this.service.put(url, {}).subscribe((res) => {
      if (res['status'] == 200) {
        $('#reject').modal('hide')
        this.data.upiStatus = 'REJECTED'
        this.service.toasterSucc('Rejected')
        this.viewDetail()
      }
    })
  }
}
