import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';
@Component({
  selector: 'app-contactus-view',
  templateUrl: './contactus-view.component.html',
  styleUrls: ['./contactus-view.component.css']
})
export class ContactusViewComponent implements OnInit {
  contactUsId: any;
  skirtListArray: any;
  colorList : any = [];
  editData:any
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService) { }

  ngOnInit() {
    this.activatedroute.queryParams.subscribe((res) => {
      this.contactUsId = res.contactUsId;
    })
    this.viewBlog()
  }

  
 viewBlog(){
  this.commonService.showSpinner()
this.commonService.get("static/get-contact-us-list?contactUsId="+this.contactUsId).subscribe((res:any)=>{
  if (res.status=200) {
    this.editData=res.data[0];
    this.commonService.hideSpinner()

  }
 },err=>{

   this.commonService.hideSpinner();
   if(err['status']=='401'){
     this.commonService.toasterErr('Unauthorized Access');
   }else{
   this.commonService.toasterErr('Please wait for few minutes & reload the site.');
}
 })

} 
}
