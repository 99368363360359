import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { IMyDateModel, IMyDpOptions } from 'mydatepicker';
import { ServiceService } from 'src/app/service.service';
declare var $: any

@Component({
  selector: 'app-list-academy',
  templateUrl: './list-academy.component.html',
  styleUrls: ['./list-academy.component.css']
})
export class ListAcademyComponent implements OnInit {
  totalRecord: any;
  flip: boolean = false;
  pageSize: number = 100

  userID: any;
  action: any;
  staffList: any = [];
  pageNumber: number = 1;
  totalItems: any;
  searchForm: FormGroup;
  staffLength: any;
  ipAddress: any;
  IsToDate: boolean = true;
  beDisable: boolean = true;
  calender: any = { todate: '', formdate: '' }
  minAge: Date;
  fromDate: any = ''
  twoDate: any = ''
  constructor(public router: Router, public service: ServiceService, public http: HttpClient) {
  }

  ngOnInit() {
    this.searchForm = new FormGroup({
      categoryName: new FormControl(''),
      difficulty: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl(''),
      search: new FormControl(''),
      title: new FormControl('')
    })
    this.dateValidation()
    this.getAcademyCategoryList()
    this.getAcademyList();
  }

  formdate() {
    this.fromDate = new Date(this.calender.formdate)
    this.fromDate = this.fromDate.getTime()
    this.IsToDate = false;

  }
  pagination(page) {
    this.pageNumber = page;
    this.getAcademyList()
  }


  todate() {
    this.twoDate = new Date(this.calender.todate)
    this.twoDate = this.twoDate.getTime()
  }
  dateValidation() {
    var today = new Date();
    var minAge = 0;
    this.minAge = new Date(today.getFullYear() - minAge, today.getMonth(), today.getDate());
  }

  editAcademy(id) {
    this.router.navigate(['/edit-academy'], { queryParams: { id } })
  }

  /**************** Date managing***************/
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableSince: { year: 0, month: 0, day: 0 }
  };
  public toDate: IMyDpOptions = {
    dateFormat: 'yyyy-mm-dd',
    editableDateField: false,
    openSelectorOnInputClick: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };

  onDateChanged() {
    let d = new Date();
    let copy1 = this.getCopyOfOptions();
    copy1.disableSince = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate()
    };
    this.myDatePickerOptions = copy1;
  }
  getCopyOfOptions(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.myDatePickerOptions));
  }


  public onChange(event: IMyDateModel) {
    if (event.formatted) {
      this.beDisable = false
      let d: Date = new Date(event.jsdate.getTime());
      d.setDate(d.getDate() - 1);
      let copy: IMyDpOptions = this.getCopyOfToDateOpt();
      copy.disableUntil = {
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      };
      this.toDate = copy;


    }

  }
  getCopyOfToDateOpt(): IMyDpOptions {
    return JSON.parse(JSON.stringify(this.toDate));
  }

  exportAsXLSX() {
    let dataArr = [];
    this.staffList.forEach((element, ind) => {
      dataArr.push({
        "ID": ind + 1,
        "Name": element.firstName + '' + element.lastName ? element.firstName + ' ' + element.lastName : '--',
        "Email": element.email ? element.email : '--',
        "Role": element.role ? element.role : '--',
        "Created Date & Time": element.createdTime ? element.createdTime : '--',
        "Last Login Date & Time": element.lastLoginTime ? element.lastLoginTime : '--',
      })

    })
    this.service.exportAsExcelFile(dataArr, 'Sub Admin List');

  }

  searchFormSubmit() {
    if (this.searchForm.value.categoryName || this.searchForm.value.fromDate || this.searchForm.value.toDate || this.searchForm.value.difficulty || this.searchForm.value.title) {
      this.getAcademyList()
    }
  }

  resetSearchForm() {
    if (this.searchForm.value.categoryName || this.searchForm.value.fromDate || this.searchForm.value.toDate || this.searchForm.value.difficulty || this.searchForm.value.title) {
      this.pageNumber = 1
      this.searchForm.reset({
        categoryName: '',
        difficulty: '',
        fromDate: '',
        toDate: '',
        search: '',
        title: ''
      })
      this.getAcademyList()
    }
  }


  addAcademy() {
    this.router.navigate(['/add-academy'])
  }
  categoryManagement() {
    this.router.navigate(['/list-academy-category'])
  }

  categoryListArray: any = []
  getAcademyCategoryList() {
    var url = "static/view-academy-category-list";
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.categoryListArray = res.data.List
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  academyListArray: any = []
  getAcademyList() {
    let url 
    
      if(this.searchForm.value.categoryName || this.searchForm.value.difficulty || this.searchForm.value.title){
        url = `${'static/admin/search-and-filter-for-academy?page=' + (this.pageNumber - 1) + ('&pageSize=' + this.pageSize) + ("&fromDate=" + 0) + ("&toDate=" + 0) +
        (this.searchForm.value.categoryName ? ('&topicName=' + this.searchForm.value.categoryName) : '') +
        (this.searchForm.value.difficulty ? ('&academyStatus=' + this.searchForm.value.difficulty) : '') +
        (this.searchForm.value.title ? ('&title=' + this.searchForm.value.title) : '')
        }`

      }else{
        url = `${'static/admin/search-and-filter-for-academy?page=' + (this.pageNumber - 1) + ('&pageSize=' + this.pageSize) +
        (this.searchForm.value.fromDate ? ("&fromDate=" + Math.round(new Date(this.searchForm.value.fromDate).getTime())) : '0') +
        (this.searchForm.value.toDate ? ("&toDate=" + Math.round(new Date(this.searchForm.value.toDate).getTime())) : '0') +
        (this.searchForm.value.categoryName ? ('&topicName=' + this.searchForm.value.categoryName) : '') +
        (this.searchForm.value.difficulty ? ('&academyStatus=' + this.searchForm.value.difficulty) : '') +
        (this.searchForm.value.title ? ('&title=' + this.searchForm.value.title) : '')
        }`
      }
    this.service.showSpinner();
    this.service.get(url).subscribe((res: any) => {
      this.service.hideSpinner();
      if (res.status == 200) {
        this.academyListArray = res.data.List
        this.totalRecord = res.data.count
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  viewAcademy(id) {
    this.router.navigate(['/view-academy'], { queryParams: { id: id } })
  }

  academyId: any;
  deleteCategoryModal(academyId) {
    $('#deleteAcademy').modal('show')
    this.academyId = academyId
  }

  deleteCategory() {
    let apiReqUrl: any = "static/delete-academy-by-subacademic-id?subAcademiccId=" + this.academyId
    this.service.showSpinner();
    this.service.delete(apiReqUrl).subscribe((res: any) => {
      $('#deleteAcademy').modal('hide');
      if (res.status == 200) {
        this.service.hideSpinner();
        this.getAcademyList();
        this.service.toasterSucc(res.message);
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    })
  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.academyListArray = this.academyListArray.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.academyListArray = this.academyListArray.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
