import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-view-settlement',
  templateUrl: './view-settlement.component.html',
  styleUrls: ['./view-settlement.component.css']
})
export class ViewSettlementComponent implements OnInit {
  contentId: any;
  skirtListArray: any;
  colorList: any = [];
  editData: any
  planId: any
  totalLeft: number = 0
  managementShow: any

  id: any;
  fixedDepositDetails: any;
  swapDetails: any
  withdrawDetails: any

  swapTransactionHash: any = ''
  fdTransactionHash: any = '';
  walletTransactionHash: any = ''
  constructor(private activatedroute: ActivatedRoute, public commonService: ServiceService, private router: Router) {
    this.activatedroute.queryParams.subscribe((res) => {
      this.managementShow = res.managementShow;
      this.id = res.id
    })
  }

  ngOnInit() {
    switch (this.managementShow) {
      case 'WALLET':
        this.viewWithdrawList();
        break;
      case 'FIXED':
        this.viewFixedDeposit();
        break;
      case 'SWAP':
        this.getSwapList();
        break
    }
  }


  viewFixedDeposit() {
    let apiReqUrl = `${'wallet/view-fixed-deposit-by-id?fixedDepositId=' + this.id}`
    this.commonService.showSpinner()
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.fixedDepositDetails = res.data[0]
        this.commonService.hideSpinner()
      }
      else {
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  getSwapList() {
    let apiReqUrl = `${'wallet/get-swap-awaiting-redeemed-byId?swapId=' + this.id}`
    this.commonService.showSpinner()
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.swapDetails = res.data
        this.commonService.hideSpinner()
      }
      else {
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  viewWithdrawList() {
    let apiReqUrl = `${'wallet/wallet/settle-withdaw-list-bt-id?withDrawRequestId=' + this.id}`
    this.commonService.showSpinner()
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.withdrawDetails = res.data
        this.commonService.hideSpinner()
      }
      else {
        this.commonService.hideSpinner();
      }
    }, err => {
      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  settleNowFixed() {
    let apiReqUrl = `${'wallet/settle-now?fixedDepositId=' + this.id + '&txnHash=' + this.fdTransactionHash
      }`
    // let apiReqUrl = 'wallet/settle-now'
    // let apiReqData = [this.id]
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.hideSpinner()
        this.commonService.toasterSucc('Fixed deposit request settled successfully.');
        this.router.navigate(['/settlement-management'])
      }
      else {
        this.commonService.hideSpinner();
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  settleNowSwap() {
    let apiReqUrl = `${'wallet/settle-now-swap?swapId=' + this.id + '&txnHash=' + this.swapTransactionHash}`
    // let apiReqUrl = 'wallet/settle-now-swap'
    let apiReqData = [this.id]
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, apiReqData).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.hideSpinner()
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/settlement-management'])
      }
      else {
        this.commonService.hideSpinner();
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  settleNowWithdraw() {
    let apiReqUrl = `${'wallet/wallet/settle-now-withdaw-request?withDrawRequestId=' + this.id}`
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.hideSpinner()
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/settlement-management'])
      }
      else {
        this.commonService.hideSpinner();
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  // https://java-centralizedexchange.mobiloitte.org:7026/wallet/cancel-swap?actionSwap=Rejected&swapId=123213421

  cancelSwap() {
    let apiReqUrl = `${'wallet/cancel-swap?actionSwap=Rejected&swapId=' + this.id}`
    this.commonService.showSpinner()
    this.commonService.postApi(apiReqUrl, {}).subscribe((res: any) => {
      if (res.status == 200) {
        this.commonService.hideSpinner()
        this.commonService.toasterSucc(res.message);
        this.router.navigate(['/settlement-management'])
      }
      else {
        this.commonService.hideSpinner();
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        //  this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
}
