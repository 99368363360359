import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $

@Component({
  selector: 'app-community-list',
  templateUrl: './community-list.component.html',
  styleUrls: ['./community-list.component.css']
})
export class CommunityListComponent implements OnInit {
  currTab: any = 'English';
  faqData: any = [];
  pageNumber: number = 1
  faqId: any;
  isPublished: any;
  deleted: any;

  lan: any;
  temp = "http://www.africau.edu/images/default/sample.pdf"
  constructor(public route: Router, public service: ServiceService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.faqList()
  }

  faqList() {
    this.service.showSpinner();
    var url = `static/get-link-list`;
    this.service.get(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.faqData = res.data;

      }
      this.service.hideSpinner();
    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  isAssending: boolean = true;

  sortAgent(key) {
    if (this.isAssending) {
      this.faqData = this.faqData.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.faqData = this.faqData.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }

  addFaq(tab) {
    this.currTab = tab
    this.route.navigate(['/add-faq'], { queryParams: { tab: tab } })
  }


  editFaq(communityId) {
    this.route.navigate(['/community-edit'], { queryParams: { communityId: communityId } })
  }
  viewFaq(faqId, tab) {
    this.route.navigate(['/view-faq'], { queryParams: { faqId: faqId, lan: tab } })
  }

  publish(publish, faqId, tab) {
    this.faqId = faqId;
    this.isPublished = publish;
    this.lan = tab
    $('#publishModal').modal('show')

  }


  publishFaq() {
    let url = "static/publish-unpuqblish-faq?faqId=" + this.faqId;
    let data = {}
    this.service.showSpinner();
    this.service.post(url, data).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        $('#publishModal').modal('hide')
        this.service.toasterSucc(res.message);
        this.faqList();
      }
    }, (err) => {
      if (err['status'] == 401) {
        this.service.toasterErr('Unauthorized Access')
      }
      else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  edit() { }
  view() { }

  id: number;


  delete(id: number) {
    this.id = id;
    $("#deleteModal").modal("show");
  }
  deleteUser() {
    this.service
      .delete(`static/delete-link?linkId=${this.id}`)
      .subscribe(
        (res: any) => {
          if ((res.ststus = 200)) {
            $("#deleteModal").modal("hide");
            this.faqList();
          }
        },
        (err) => {
          if (err["status"] == "401") {
            this.service.onLogout();
            this.service.toasterErr("Unauthorized Access");
          } else {
            this.service.toasterErr("Please wait for few minutes & reload the site.");
          }
        }
      );
  }
}
