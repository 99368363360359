import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-static-content-add',
  templateUrl: './static-content-add.component.html',
  styleUrls: ['./static-content-add.component.css']
})
export class StaticContentAddComponent implements OnInit {
  aboutUs:any;
  description:any;
  currUrl: any;
  title: any;
  constructor( public service: ServiceService, public route:Router, public active:ActivatedRoute ) 
  {
    this.active.queryParams.subscribe((params)=>{
      this.currUrl=params.tab
    })
    
   }

  ngOnInit() {
  }
  

addStatic(){
  let request = {
    'pageData':this.description,
    'pageKey':this.title,
  }
 this.service.post(`static/admin/static-content/add-new-static-content`,request).subscribe((res:any)=>{
   if (res.status=200) {
     this.service.toasterSucc(res.message)
     this.route.navigate(['/statics-content'])
   }
  },err=>{
 
    this.service.hideSpinner();
    if(err['status']=='401'){
      this.service.onLogout();
      this.service.toasterErr('Unauthorized Access');
    }else{
    this.service.toasterErr('Please wait for few minutes & reload the site.');
 }
  })

}


updateLanguageData(){
  this.updateEnglishAboutUs()

}

// english
 updateEnglishAboutUs(){
    let request = {
      'pageData':this.description,
      'pageKey':this.title,
    }
   this.service.post(`static/update-static-content-data`,request).subscribe((res:any)=>{
     if (res.status=200) {
       this.service.toasterSucc(res.message)
       this.route.navigate(['/static-content-management'])
     }
    },err=>{
   
      this.service.hideSpinner();
      if(err['status']=='401'){
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      }else{
      this.service.toasterErr('Please wait for few minutes & reload the site.');
   }
    })

 }
  
}
