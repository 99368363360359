import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

declare var $: any
@Component({
  selector: 'app-list-banner',
  templateUrl: './list-banner.component.html',
  styleUrls: ['./list-banner.component.css']
})
export class ListBannerComponent implements OnInit {
  searchForm: FormGroup;
  bodyListArray: any = [];
  itemPerPage = 10;
  currentPage = 1;
  totalItems: any;
  today = new Date().toISOString().split('T')[0]
  userStatus: any = 'ACTIVE';
  bannerId: any;
  bannerStatus: string;
  action: any;
  constructor(private router: Router, public commonService: ServiceService) { }

  ngOnInit() {
    this.searchFormValidation();
    this.getEmbellishmentList();
  }

  openModal(bannerStatus, bannerId) {
    this.bannerId = bannerId;
    this.bannerStatus = bannerStatus;
    if (bannerStatus == 'BLOCK') {
      $('#block').modal('show')
    }
    else {
      $('#active').modal('show')
    }
  }


  performAction() {
    var url = 'static/active-block-banner?bannerId=' + (this.bannerId) + '&status= ' + (this.bannerStatus);
    this.commonService.showSpinner();
    this.commonService.post(url, '').subscribe(res => {
      this.commonService.hideSpinner();
      if (res['status'] == 200) {
        if (this.bannerStatus == 'BLOCK') {
          $('#block').modal('hide');
          this.commonService.toasterSucc('User Blocked Successfully');
          this.getEmbellishmentList()
        }
        else {
          $('#active').modal('hide');
          this.commonService.toasterSucc('User Activated Successfully');
          this.getEmbellishmentList()
        }
      }
    }, err => {

      this.commonService.hideSpinner();
      if (err['status'] == '401') {
        this.commonService.onLogout();
        this.commonService.toasterErr('Unauthorized Access');
      } else {
        this.commonService.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  searchFormValidation() {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      status: new FormControl(''),
      fromDate: new FormControl(''),
      toDate: new FormControl('')
    });
  }
  searchFormSubmit() {

    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.getEmbellishmentList()
    }
  }
  searchFormReset() {
    if (this.searchForm.value.search || this.searchForm.value.status || this.searchForm.value.fromDate || this.searchForm.value.toDate) {
      this.searchForm.reset({
        search: '',
        status: '',
        fromDate: '',
        toDate: ''
      });
      this.getEmbellishmentList()
    }
  }

  selectStatus() {
    this.currentPage = 1
  }
  getEmbellishmentList() {
    let apiReqUrl: any = `static/get-banner-list?page=0&pageSize=${this.itemPerPage}`
    this.commonService.showSpinner();
    this.commonService.get(apiReqUrl).subscribe((res: any) => {
      if (res.status == 200) {
        this.bodyListArray = res.data ? res.data : '';
        this.totalItems = res.data.count
        this.commonService.hideSpinner();
      } else {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    }, (err) => {
      if (err.status == 404) {
        this.bodyListArray = []
        this.totalItems = 0
        this.commonService.hideSpinner();
      }

    })
  }

  pagination(event) {
    this.currentPage = event;
    this.getEmbellishmentList()
  }

  addBody() {
    this.router.navigate(['/add-banner'])
  }
  viewBody(bannerId) {
    this.router.navigate(['/view-banner'], { queryParams: { bannerId: bannerId } })
  }
  editBody(bannerId) {
    this.router.navigate(['/edit-banner'], { queryParams: { bannerId: bannerId } })
  }
  deleteCategoryModal(bannerId) {
    $('#deleteCategory').modal('show')
    this.bannerId = bannerId
  }
  deleteCategory() {
    let apiReqUrl: any = `static/delete-banner?id=${this.bannerId}`
    this.commonService.showSpinner();
    this.commonService.delete(apiReqUrl).subscribe((res: any) => {
      $('#deleteCategory').modal('hide');
      if (res.status == 200) {
        this.getEmbellishmentList()
        this.commonService.toasterSucc(res.message);
      } else {
        this.commonService.hideSpinner();
        this.commonService.toasterErr(res.message)
      }
    })
  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.bodyListArray = this.bodyListArray.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.bodyListArray = this.bodyListArray.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
