import { Component, OnInit } from "@angular/core";
import { ServiceService } from "../service.service";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { DatePipe } from "@angular/common";
declare var $: any;
@Component({
  selector: "app-kyc-management",
  templateUrl: "./kyc-management.component.html",
  styleUrls: ["./kyc-management.component.css"],
})
export class KycManagementComponent implements OnInit {
  searchText: string = "";
  pageNumber: number = 1;
  status: string;
  kycList: any = [];
  kycData: any = {};
  userKycId: any;
  kycDetail: any;
  itemsPerPage: any = 100;
  flip: boolean = false;
  countryList: any = [];
  country: any;
  transferForm: FormGroup;
  val: any
  flag: any
  dashStatus: any
  today: any = new Date().toISOString().split('T')[0]
  totalItems: any;
  constructor(public service: ServiceService, public router: Router, public activatedRoute: ActivatedRoute,private datePipe:DatePipe) {
    this.activatedRoute.queryParams.subscribe(res => {
      this.flag = res.flag
      if (this.flag == 'pending') {
        this.dashStatus = 'PENDING'
      } else if (this.flag == 'accepted') {
        this.dashStatus = 'ACCEPTED'
      } else if (this.flag == 'rejected') {
        this.dashStatus = 'REJECTED'
      }
    })
  }

  ngOnInit() {
    this.checkTransferFormValidations()
    this.getListOfKyc();
    this.getCountryList();
  }


  // form validatioons 
  checkTransferFormValidations() {
    this.transferForm = new FormGroup({
      'kycStatus': new FormControl(''),
      'fromDate': new FormControl(''),
      'toDate': new FormControl(''),
      'search': new FormControl(''),
      'country': new FormControl(''),

    })
    if (this.flag == 'pending') {
      this.transferForm.patchValue({
        kycStatus: 'PENDING'
      })
    } else if (this.flag == 'accepted') {
      this.transferForm.patchValue({
        kycStatus: 'ACCEPTED'
      })
    } else if (this.flag == 'rejected') {
      this.transferForm.patchValue({
        kycStatus: 'REJECTED'
      })
    }
  }
  reset() {
    this.transferForm.reset({
      fromDate: "",
      toDate: "",
      country: "",
      search: "",
      kycStatus: "",
    });
    this.dashStatus = ""
    this.getListOfKyc();
  }

  // get country list
  getCountryList() {
    this.service.get("account/get-country-list").subscribe((res) => {
      if (res["status"] == 200) {
        this.countryList = res["data"];
      }
    });
  }
  gotoview(id,email) {
    this.router.navigate(["/kyc-action/id"], {
      queryParams: { id: id,eamil:email },
    });
  }
  // getStatus Function
  getStatus(event) {
    this.pageNumber = 1;
    this.status = event.target.value;
    if (this.status != "") {
      this.getListOfKyc();
    } else {
      this.getListOfKyc();
    }
  }
  forSerachValue() {
    let fromDate = Date.parse(this.transferForm.value.fromDate);
    let toDate = Date.parse(this.transferForm.value.toDate);
    let search = this.transferForm.value.search;
    let kycStatus = this.transferForm.value.kycStatus
    let country = this.transferForm.value.country
    let url = `account/admin/kyc-management/filter-kyc-users-list?page=${this.pageNumber - 1}&pageSize=${this.itemsPerPage}${this.transferForm.value.fromDate ? "&fromDate=" + fromDate : ""}${this.transferForm.value.toDate ? "&toDate=" + toDate : ""}${this.transferForm.value.country ? "&country=" + country : ""}${this.transferForm.value.kycStatus ? "&kycStatus=" + kycStatus : ""}${this.transferForm.value.search ? "&search=" + search : ""}`
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.kycList = res.data.list;
        }
        this.kycDetail = res['data'].totalCount
        this.service.hideSpinner();
      },
      (err: any) => {
        this.service.hideSpinner();
        this.service.toasterErr(err.error.message);
      }
    );
  }

  // getListOFKYC Function
  getListOfKyc() {
    let url = `${"account/admin/kyc-management/filter-kyc-users-list?page=" +
      (this.pageNumber - 1) +
      "&pageSize=100"}${this.dashStatus ? "&kycStatus=" + this.dashStatus : ""}`;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.totalItems  = res["data"].totalCount;
          this.kycDetail = res["data"].totalCount;
          this.kycList = res["data"]["list"];
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Please wait for few minutes & reload the site.");
        }
      }
    );
  }

  // Change Page Number
  changePageNumber(page) {
    this.pageNumber = page;

    this.getListOfKyc();
  }

  // Get Particular KYC Detail
  getParticularKycDetail(userId, kycId) {
    this.userKycId = kycId;
    var url = "account/admin/kyc-management/get-kyc-details?userId=" + userId;
    this.service.showSpinner();
    this.service.get(url).subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res["status"] == 200) {
          this.kycData = res["data"]["document"];

          $("#view").modal("show");
        } else {
          this.service.toasterErr(res["message"]);
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err["status"] == "401") {
          this.service.onLogout();
          this.service.toasterErr("Unauthorized Access");
        } else {
          this.service.toasterErr("Please wait for few minutes & reload the site.");
        }
      }
    );
  }

  documentActionFunc(action, documentNumber, documentId) {
    var apiReq = {
      documentId: documentId,
      kycId: this.userKycId,
      reason: action == "ACCEPT" ? "Valid Document" : "Invalid Document",
      status: action,
    };
    this.service.showSpinner();
    this.service
      .post("account/admin/kyc-management/doc-status", apiReq)
      .subscribe(
        (res) => {
          this.service.hideSpinner();
        },
        (err) => {
          this.service.hideSpinner();
        }
      );
  }

  disableSearchbtn() {

    if (this.transferForm.value.kycStatus || this.transferForm.value.fromDate || this.transferForm.value.toDate || this.transferForm.value.submit || this.transferForm.value.country || this.transferForm.value.search) {
      return false
    }
    else {
      return true
    }

  }
getExportData(){
  let url = `${"account/admin/kyc-management/filter-kyc-users-list?page=" +
      (this.pageNumber - 1) +
      "&pageSize="}${this.totalItems}${this.dashStatus ? "&kycStatus=" + this.dashStatus : ""}`;
    this.service.get(url).subscribe(
      (res) => {
        if (res["status"] == 200) {
          this.exportAsXLSX(res["data"]["list"])
        } else {
          this.service.toasterErr(res["message"]);
        }
      }
    );
}
  exportAsXLSX(kycList) {
    let dataArr = [];
    kycList.forEach((element, ind) => {
      dataArr.push({
        "S.No.": ind + 1,
        "Unique ID": element.randomId ? element.randomId : '--',
        "Referral ID": element.myReferralCode  ? element.myReferralCode : '--',
        "User Name": element.uniqueName ? element.uniqueName : '',
        "Referral User Name": element.referredUniqueName  ? element.referredUniqueName : '--',
        "Full Name":  element.firstName ? element.firstName : '' + ' ' +element.middleName ? element.middleName :'' + ' ' +element.lastName  ? element.lastName  :'' ,
        "Email Id": element.email ? element.email : '--',
        "Phone no.":   element.phoneNo ? element.phoneNo : '--',
        "Kyc Status":element.kycStatus ? element.kycStatus  :'--',
        "Country" :element.country ? element.country : '--',
        "Date & Time" : element.createTime ? this.datePipe.transform(element.createTime,'medium') : '--',
      })
    })
    this.service.exportAsExcelFile(dataArr, 'KYC Management');
  }
  isAssending: boolean = true;
  sortAgent(key) {
    if (this.isAssending) {
      this.kycList = this.kycList.sort((a, b) => {
        return a[key] > b[key] ? 1 : -1;
      });
    } else {
      this.kycList = this.kycList.sort((a, b) => {
        return a[key] < b[key] ? 1 : -1;
      });
    }
  }
}
