import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceService } from 'src/app/service.service';

@Component({
  selector: 'app-add-academy',
  templateUrl: './add-academy.component.html',
  styleUrls: ['./add-academy.component.css']
})
export class AddAcademyComponent implements OnInit {
  addForm: FormGroup;
  categoryListArray: any = [];
  imageUrl: any;

  constructor(public service: ServiceService, public route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.addFormValidation();
    this.getCategoryList();
  }
  addFormValidation() {
    this.addForm = new FormGroup({
      'categoryId': new FormControl('', [Validators.required]),
      'academyType': new FormControl('', [Validators.required]),
      'title': new FormControl('', [Validators.required]),
      'time': new FormControl('', [Validators.required]),
      'description': new FormControl('', [Validators.required]),
      'image': new FormControl('', [Validators.required])
    })
  }

  getCategoryList() {
    this.service.showSpinner();
    this.service.get('static/view-academy-category-list').subscribe((res: any) => {
      if (res['status'] == 200) {
        this.service.hideSpinner();
        this.categoryListArray = res.data.List
      } else {
        this.service.hideSpinner();
        this.categoryListArray = []
      }
    }, (err: any) => {
      this.categoryListArray = []
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }
  submitForm() {
    let apiReqUrl = `${'static/add-academy' + '?AcademicId=' + this.addForm.value.categoryId + '&academyStatus=' + this.addForm.value.academyType}`
    var apiReq = {
      "description": this.addForm.value.description,
      "img": this.imageUrl,
      "subAcdName": this.addForm.value.title,
      "time": Number(this.addForm.value.time)
    }
    this.service.showSpinner();
    this.service.post(apiReqUrl, apiReq).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.hideSpinner();
        this.service.toasterSucc(res.message)
        this.router.navigate(['/list-academy'])
      } else {
        this.service.hideSpinner();
        this.service.toasterErr(res.message)
      }
    }, (err: any) => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

  uploadImg($event): void {
    var img = $event.target.files[0];
    this.uploadImageFunc(img);
  }
  uploadImageFunc(img) {
    var fb = new FormData();
    fb.append('file', img)
    this.service.showSpinner();
    this.service.postApi('account/upload-file', fb).subscribe(res => {
      if (res['status'] == '200') {
        this.imageUrl = res['data'];
        this.service.hideSpinner();
      }
    }, err => {
      this.service.hideSpinner();
      if (err['status'] == '401') {
        this.service.onLogout();
        this.service.toasterErr('Unauthorized Access');
      } else {
        this.service.toasterErr('Please wait for few minutes & reload the site.');
      }
    })
  }

}
